import moment from 'moment';
import _merge from 'lodash/merge';
import _debounce from 'lodash/debounce';
import {
    APP_CONTENT_RESIZE_EVENT
} from 'services/StudioEvents';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { PLOT_BAND_OFFLINE_PREFIX, PLOT_BAND_INCOMPLETE_DATA_PREFIX } from 'services/charting/data-converter';
import { RANGE_DURATIONS } from 'services/charting/constants';

const assertDependency = (service, serviceName) => {
    if (!service) {
        throw new Error(
            `Service "${serviceName}" is required to be passed to the AbstractChartController constructor`
        );
    }
};

const assertOverride = methodName => {
    throw new Error(
        `Method "${methodName}" should be overridden in the child class`
    );
};

const PAN_TRACKING_DEBOUNCE_DELAY = 250; // in milliseconds

const CHART_X_AXIS_INDEX = 0;
const CHART_DATA_SERIES_INDEX = 0;
const CHART_SPACER_SERIES_INDEX = 1;

const MESSAGE_LOADING = 'Loading data from server...';

/* eslint class-methods-use-this: 0 */

/**
 * @class AbstractChartController
 *
 * @external {moment} https://momentjs.com/
 *
 * @property {SensorEventsLoader} SensorEventsLoader
 * @property {ToastService} ToastService
 * @property {Object} thing
 * @property {boolean} initialized
 * @property {Function} onInitialized Callback to notify parent about chart initialization
 * @property {number} spacerStart The beginning of the scrollable time span
 * @property {number} spacerEnd The end of the scrollable time span (most often current time)
 *
 * These properties are used in charts with variable Y values e.g. temperature
 * @property {number} spacerMin The minimum data value of the Y Axis
 * @property {number} spacerMax The maximum data value of the Y Axis
 *
 * @property {number} initialXAxisMin The beginning of the initial scrollable window in unix timestamp format
 * @property {number} initialXAxisMax The end of the initial scrollable window in unix timestamp format
 *
 * @property {number} chartMin The beginning of the loaded timespan in unix timespan format
 * @property {number} chartMax The end of the loaded timestamp in unix timespan format (current time)
 *
 * @property {?moment} dataLoadedSince The beginning of the currently loaded time span
 * @property {boolean} loadingSince The flag telling if the data is currently being loaded
 * @property {boolean} needsReloadSince The flag telling if the data needs to be reloaded from the new since
 */
class AbstractChartController {
    constructor({
        SensorEventsLoader,
        ToastService,
        $rootScope,
        EventEmitter,
    }) {
        assertDependency(SensorEventsLoader, 'SensorEventsLoader');
        assertDependency(ToastService, 'ToastService');
        assertDependency($rootScope, '$rootScope');
        assertDependency(EventEmitter, 'EventEmitter');

        this.SensorEventsLoader = SensorEventsLoader;
        this.ToastService = ToastService;
        this.$rootScope = $rootScope;
        this.EventEmitter = EventEmitter;

        this.initializeChart = this.initializeChart.bind(this);
        this.processLazyEvents = this.processLazyEvents.bind(this);
        this.handleError = this.handleError.bind(this);
        this.onAfterSetExtremes = this.onAfterSetExtremes.bind(this);
        this.handleChartSelection = this.handleChartSelection.bind(this);
        this.updateSpacerSeries = this.updateSpacerSeries.bind(this);

        // Prevents loadMissingData from being called more frequently than PAN_TRACKING_DEBOUNCE_DELAY 
        // milliseconds. This function will be called any time the time range changed, either because 
        // the chart was panned or zoomed, or because the time picker was used to change the time range.
        this.loadMissingData = _debounce(
            this.loadMissingData.bind(this),
            PAN_TRACKING_DEBOUNCE_DELAY
        );
    }

    $onInit() {        
        this.initialized = false;
        
        // Sets the user scrollable time range
        this.spacerStart = moment()
            .subtract(RANGE_DURATIONS[this.greatestRangeLabel], 'milliseconds')
            .valueOf();
        this.spacerEnd = moment().valueOf();

        // Y-axis
        this.spacerMin = 0;
        this.spacerMax = 0;

        // Sets the time range to fetch data for
        const chartMin = moment()
            .subtract(1, 'day')
            .startOf('day');
        const chartMax = moment();
        this.chartMin = chartMin.valueOf();
        this.chartMax = chartMax.valueOf();

        // Defines the initial extremes for the chart
        this.initialXAxisMax = moment().valueOf();
        this.initialXAxisMin = moment()
            .subtract(1, 'day')
            .valueOf();
        
        this.setLoadedEvents([]);
        this.setCurrentExtremes([this.initialXAxisMin, this.initialXAxisMax]);

        // Keeps track of the earliest timestamp that we have finished loading events for.
        this.dataLoadedSince = chartMin;

        // Is set to true while loading new events
        this.isLoadingData = false;

        // Is set to true if there was a reason to load more events while events were
        // being loaded (loadingSince = true). In this case, the events will be loaded
        // after the current load is finished.
        this.needsReloadSince = false;

        // Raw events
        this.loadedEvents = [];
        // Highchart formatted events or aggregated data
        this.formattedData = [];
        // Highchart formatted samples (will be empty if range is more than 8 days)
        this.formattedSamples = [];

        // Set to true while the extremes are being updated by the spacer ticker (or new events).
        // This is to prevent requesting new data from the API every time the spacer ticker updates.
        // We'll get new events from the stream, so the API call is unnecessary.
        this.extremesUpdatedThroughTicker = false;

        this.removeResizeListener = this.$rootScope.$on(
            APP_CONTENT_RESIZE_EVENT, () => {

                // This is a workaround to force the chart to redraw plotbands when the window is resized.
                const allPlotBandsIds = [] 
                this.xAxis.plotLinesAndBands.forEach(band => {
                    if (band.id.startsWith(PLOT_BAND_OFFLINE_PREFIX) || band.id.startsWith(PLOT_BAND_INCOMPLETE_DATA_PREFIX)) {
                        allPlotBandsIds.push(band.id)
                    }
                })
                // Clear them all before redraw
                allPlotBandsIds.forEach(bandId => { 
                    this.xAxis.removePlotBand(bandId)
                })
                setTimeout(() => {
                    this.plotBands?.forEach(band => { // eslint-disable-line no-unused-expressions
                        this.xAxis.addPlotBand(band);
                    })
                }, 1)
            }
        )
        
        this.initializeSince(chartMin, chartMax);
    }

    $onDestroy() {
        this.removeResizeListener()
        this.stopSpacerTicker();
    }

    get xAxis() {
        return this.chart.xAxis[CHART_X_AXIS_INDEX];
    }

    get dataSeries() {
        return this.chart.series[CHART_DATA_SERIES_INDEX];
    }

    get spacerSeries() {
        return this.chart.series[CHART_SPACER_SERIES_INDEX];
    }

    /**
     * This method must be overridden!
     *
     * It should return the array of the event names that should be fetched from the API.
     * These events are used to draw the chart properly.
     */
    getEventTypes() {
        assertOverride('getEventTypes()');
    }

    /**
     * This method must be overridden when dataToLoad() returns "aggregated!
     * 
     * It should return the array of the aggregation fields that should be fetched from the API.
     * @returns {Array} An array of aggregation fields
     */
    getAggregationFields() {
        assertOverride('getAggregationFields()');
    }

    /**
     * This method must be overridden!
     *
     * It should accept the array of raw events fetched from the API,
     * and should return the object { data, plotBands } where:
     * data is an array of Highcharts point format,
     * plotBands is an array of Highcharts plot bands format.
     */
    convertEvents() {
        assertOverride('convertEvents(events)');
    }

    /**
     * This method must be overridden!
     * 
     * It should accept the aggregated data fetched from the API,
     * and should return the object { data, plotBands } where:
     * data is an array of Highcharts point format,
     * plotBands is an array of Highcharts plot bands format.
     */
    convertAggregated() {
        assertOverride("convertAggregated(aggregatedData)");
    }

    /**
     * This method could be overridden in some cases.
     *
     * @returns array
     */
    convertSamples() {
        return []
    }

    /**
     * This method must be overridden!
     *
     * It should accept the data (from the convertEvents method)
     * and return the Highcharts series config.
     */
    convertToSeries() {
        assertOverride(
            'convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax)'
        );
    }

    /**
     * This method must be overridden!
     *
     * It should return the Highcharts configuration preset that defines the chart-specific options.
     */
    getConfigPreset() {
        assertOverride('getConfigPreset()');
    }

    /**
     * Used by AbstractChartControlled to determine if it should load raw events, aggregated data,
     * or no data at all. This function _must_ return either "events", "aggregated", or "".
     * 
     * @param {number} days The number of days to load data for
     * @returns {string} Either "events", "aggregated", or "" for no data
     */
    dataToLoad(days) {
        // The default is to return raw events for up to 31 days, and aggregated data for longer time ranges.
        return days > 31 ? "aggregated" : "events";
    }

    /**
     * This method must be overridden!
     *
     * It should add state event to chart.
     */
    onStateEventReceived() {
        assertOverride('onStateEventReceived(eventData)');
    }

    /**
     * This method could be overridden in some cases.
     *
     * @returns boolean
     */
    shouldUseStockChart() {
        return false;
    }

    /**
     * This method could be overridden in some cases.
     *
     * @returns object
     */
    onChartPan(event) {
        return event 
    }

    /**
     * This method could be overridden in some cases.
     *
     * It should update the current offline plot band to stretch to the current moment.
     */
    syncOfflinePlotBand() {
        const lastSeen = this.thing.lastSeen;
        const from = moment(lastSeen || this.chartMin).valueOf();
        const id = DataConverter.plotBandId(from);

        this.xAxis.removePlotBand(id);
        this.xAxis.addPlotBand(DataConverter.plotBand(from, this.chartMax));
    }

    /**
     * This method could be overridden in some cases.
     *
     * It should apply an update for a chart if it is required e.g. extend the line to current moment.
     */
    addNetworkEventPoint() {}

    /**
     * This method could be overridden in some cases.
     */
    syncAdditionalDataSeries() {}

    /**
     * Returns the desired range to load data for, based on the current range visible in the chart.
     * This can be overridden in subclasses to either extend the range to ensure all the necessary
     * data is loaded, or limited to prevent unnecessary data from being loaded. 
     * 
     * Must return on object of the following format: { start: Moment, end: Moment }
     * 
     * The default behavior is to extend the chart range by either the bucket size (if we're aggregating),
     * or by 1 hour (if we're not aggregating).
     * 
     * @param {Moment} chartStart The start time of the chart
     * @param {Moment} chartEnd The end time of the chart
     */
    dataLoadRange(chartStart, chartEnd) {
        let extension = 3600

        const bucketSize = this.aggregationBucketSizeSeconds(chartStart, chartEnd)
        if (bucketSize) {
            extension = bucketSize
        }

        return {
            start: chartStart.subtract(extension, 'seconds'),
            end: chartEnd.add(extension, 'seconds')
        }
    }

    /**
     * The number of seconds to use as the bucket size for aggregation.
     * 
     * Can optionally be overridden in subclasses to provide a custom bucket size.
     * 
     * @param {Moment} start The start time to load data from
     * @param {Moment} end The end time to load data to
     * @returns 
     */
    aggregationBucketSizeSeconds(start, end) {
        return this.SensorEventsLoader.aggregationBucketSizeSeconds(start, end);
    }

    /**
     * Loads either events or an aggregated result from the API.
     * 
     * @param {Moment} startTime The start time to load data from
     * @param {Moment} endTime The end time to load data to
     * @returns {Promise} A promise that resolves to an array of events or an aggregated result
     */
    async loadEvents(startTime, endTime) {
        // Calculate the bucket size based on the chart range and not the provided startTime and endTime
        // since those might be extended to load more data than the chart.
        const chartMin = moment(this.currentExtremes[0]);
        const chartMax = moment(this.currentExtremes[1]);

        const days = endTime.diff(startTime, 'days');
        const dataToLoad = this.dataToLoad(days);
        
        // Load aggregated data if the range is more than 30 days
        if (dataToLoad === 'aggregated') {
            const bucketSize = this.aggregationBucketSizeSeconds(chartMin, chartMax);
            
            const aggregatedData = await this.SensorEventsLoader.loadAggregatedData(
                this.thing.name,
                this.getAggregationFields(),
                startTime,
                endTime,
                `${bucketSize}s`,
            ).catch(this.handleError);
            
            const { plotBands, data } = this.convertAggregated(aggregatedData);
            
            this.formattedData = data;
            this.formattedSamples = [];
            this.setLoadedEvents([]);

            this.setMetadata({
                bucketSizeSeconds: bucketSize,
            });
            
            return plotBands;
        } else if (dataToLoad === 'events') {
            const events = await this.SensorEventsLoader.loadSince(
                this.thing.name,
                this.getEventTypes(),
                startTime,
                endTime
            ).catch(this.handleError);
            
            
            // Get Highchart formatted data
            const { plotBands, data } = this.convertEvents(events);
            this.formattedData = data
            if (days < 8) {
                this.formattedSamples = this.convertSamples(events);
            } else {
                this.formattedSamples = [];
            }

            this.setLoadedEvents(events);

            this.setMetadata({
                bucketSizeSeconds: null, // Implies raw events
            });
            
            return plotBands;
        } 

        // The subclass has indicated that no data should be loaded
        this.formattedData = [];
        this.formattedSamples = [];
        this.setLoadedEvents([]);

        return [];
    }

    /**
     * Called by $onInit, and is the first trigger point to load data for the chart.
     * 
     * @param {Moment} startTime The start time to load initial data from
     * @param {Moment} endTime The end time to load data to
     */
    initializeSince(startTime, endTime) {
        this.loadEvents(startTime, endTime).then(this.initializeChart);
    }

    /**
     * Called after the first batch of events is loaded. Initializes the chart config,
     * including adding the events to the chart.
     * 
     * @param {Array} events The initial batch of events
     */
    initializeChart(plotBands) {
        if (this.formattedSamples.length > 0) {
            this.initializeChartConfig(this.formattedSamples, plotBands);
        } else {
            this.initializeChartConfig(this.formattedData, plotBands);
        }
        
        this.onInitialized();
    }

    /**
     * Called when events are loaded after the initial load. The array of events to set as the loaded set of events
     * @param {Array} events 
     */
    processLazyEvents(plotBands) {
        const allPlotBandsIds = [] 
        this.xAxis.plotLinesAndBands.forEach(band => {
            if (band.id.startsWith(PLOT_BAND_OFFLINE_PREFIX) || band.id.startsWith(PLOT_BAND_INCOMPLETE_DATA_PREFIX)) {
                allPlotBandsIds.push(band.id)
            }
        })
        // Clear them all before redraw
        allPlotBandsIds.forEach(bandId => { 
            this.xAxis.removePlotBand(bandId)
        })

        plotBands.forEach(band => {
            this.xAxis.addPlotBand(band);
        });

        // Show [samples] values if range is less than 8 days
        if (this.formattedSamples.length > 0) {
            this.dataSeries.setData(this.formattedSamples);    
        } else {
            this.dataSeries.setData(this.formattedData);
        }
        this.syncAdditionalDataSeries()
    }

    /**
     * Prepares the initial chart config, including the preset from subclasses, and the
     * initial batch of events and plot bands (offline, boost, etc).
     * 
     * @param {Array} data The initial batch of events
     * @param {Array} plotBands The initial plot bands (offline, boost, etc)
     */
    initializeChartConfig(data, plotBands) {
        this.chartConfig = _merge(
            {
                chart: {
                    events: {
                        selection: this.handleChartSelection
                    }
                }
            },
            ConfigPresets.Base,
            this.getConfigPreset(),
      
            this.convertToSeries(
                data,
                this.spacerStart,
                this.spacerEnd,
                this.spacerMin,
                this.spacerMax
            ),
            ConfigHelper.plotBands(plotBands),
            {
                xAxis: {
                    min: this.initialXAxisMin,
                    max: this.initialXAxisMax,
                    events: {
                        afterSetExtremes: this.onAfterSetExtremes
                    }
                }
            }
        );
        if (this.alertView) {
            this.chartConfig = _merge(this.chartConfig, ConfigPresets.AlertView);
            if (this.showBigFormat) {
                this.chartConfig.chart.height = 276;
            }
        }
        
        this.initialized = true;
    }

    onChartLoaded({ chart }) {
        this.chart = chart;
        this.setRange(this.range);
    }

    handleChartSelection() {
        this.onChartSelection();
    }

    /**
     * Called automatically be the chart every time the extremes changes.
     */
    onAfterSetExtremes({ trigger, min, max }) {
        let shouldUpdateDataSeries = true
        if (trigger === 'zoom') { 
            // The user has zoomed by dragging-to-select on the chart.
        } else if (trigger === 'navigator') {
            // The user has used the scrollbar or the arrows at the bottom of the chart
            // to change the time range.
            this.onChartPan(
                this.EventEmitter({
                    extremes: [min, max]
                })
            )
            // Avoid calling setData on Highcharts when panning the data
            shouldUpdateDataSeries = false 
        }

        // Don't load new data if the extremes were updated by the spacer ticker.
        if (this.extremesUpdatedThroughTicker === false) {
            this.loadMissingData();
        }

        this.setCurrentExtremes([min, max]);

        if (shouldUpdateDataSeries && this.loadedEvents.length > 0) {
            // TODO: This work is only necessary when new data is arriving from the stream (backfill, etc).
            // Instead of doing this work here, we should do it in the stream handler. The loadMissingData
            // will already do this indirectly (through loadData), so doing it here means the default behavior
            // is to process the data twice every time we load it.
            this.formattedData = this.convertEvents(this.loadedEvents).data
            this.formattedSamples = this.convertSamples(this.loadedEvents)
            const daysRange = moment(max).diff(moment(min), 'days')
            if (daysRange < 8 && this.formattedSamples.length > 0) {
                // Highchart can alter provided values, use a copy to avoid changing the formattedSamples
                const samplesCopy = [...this.formattedSamples] 
                this.dataSeries.setData(samplesCopy);
            } else {
                this.dataSeries.setData(this.formattedData);
            }
            this.syncAdditionalDataSeries()
        } 
    }

    /**
     * Figures out if the new start of the chart is earlier than the earliest data we have,
     * and triggers a load of the missing data if it is by calling loadDataSince.
     * Called when we might have to load more data (eg. because the time range has changed).
     */
    loadMissingData() {
        // If we're already loading data, flag that we need to reload once the current 
        // load is finished.
        if (this.isLoadingData) {
            this.needsReloadSince = true;
            return;
        }

        const { min, max } = this.xAxis.getExtremes();

        // Defines the start and end time to fetch data for. Extending the extremes
        // of the chart by an hour on either side to ensure 
        const {start, end } = this.dataLoadRange(moment(min), moment(max))

        this.chartMin = start.valueOf();
        this.chartMax = end.valueOf();

        this.chart.showLoading(MESSAGE_LOADING);
        this.isLoadingData = true;
        this.stopSpacerTicker();

        // TODO:
        // Ideally, should keep data already loaded, and only load the data needed to extend the existing segment
        // at the current resolution. If the resolution or the time range changes significantly (user scrolls fast
        // using the scrollbar), we can discard the current segment and start over.

        this.loadEvents(start, end)
            .then(this.processLazyEvents)
            .then(() => {
                this.dataLoadedSince = start;
                this.isLoadingData = false;
                this.chart.hideLoading();
                
                // Load more data if loadMissingData was called again while we were loading data.
                if (this.needsReloadSince) {
                    this.needsReloadSince = false;
                    this.loadMissingData();
                    return;
                }

                // Start the ticker to progress the timeline if the user is close enough to the end of the chart.
                if (ConfigHelper.isCloseToAxisMax(min, max, this.spacerEnd)) {
                    const interval = ConfigHelper.getRefreshInterval(min, max);
                    if (interval !== null) {
                        this.initSpacerTicker(interval);
                    }
                }

                if (this.thresholds) {
                    this.addYAxisPlotLines()
                }
            });
    }

    handleError(serverResponse) {
        this.ToastService.showSimpleTranslated('device_events_wasnt_loaded', {
            serverResponse
        });
        return [];
    }

    /**
     * Extends either offline plot bands, or state segments to the end of the timeline.
     */
    addHeartbeatEventPoint() {
        if (this.thing.offline) {
            this.syncOfflinePlotBand();
        } else {
            this.addNetworkEventPoint();
        }
    }

    /**
     * Moves the end of the chart to "now" (both chartMax and spacerEnd).
     */
    updateSpacerSeries() {
        this.extremesUpdatedThroughTicker = true;

        this.chartMax = moment().valueOf();
        this.spacerEnd = this.chartMax;
        
        // This will trigger a synchronous call to onAfterSetExtremes, meaning setData doesn't
        // return until after onAfterSetExtremes has returned.
        this.spacerSeries.setData(
            ConfigHelper.spacerSeriesData(
                this.spacerStart,
                this.spacerEnd,
                this.spacerMin,
                this.spacerMax
            )
        );

        this.extremesUpdatedThroughTicker = false;

        this.addHeartbeatEventPoint();
    }

    initSpacerTicker(delay) {
        this.spacerTicker = setInterval(this.updateSpacerSeries, delay);
    }

    stopSpacerTicker() {
        clearTimeout(this.spacerTicker);
    }

    get isCloseToAxisMax() {
        const { min, max } = this.xAxis.getExtremes();
        return ConfigHelper.isCloseToAxisMax(min, max, this.chartMax);
    }

    setRange(label) {

        // Ensure that all offline plot bands are removed before setting the new range.
        const allPlotBandsIds = [] 
        this.xAxis.plotLinesAndBands.forEach(band => {
            if (band.id.startsWith('offline-since')) {
                allPlotBandsIds.push(band.id)
            }
        })
        // Clear them all before redraw
        allPlotBandsIds.forEach(bandId => { 
            this.xAxis.removePlotBand(bandId)
        })

        const chartMin = this.spacerStart;
        const chartMax = this.spacerEnd;

        const { max } = this.xAxis.getExtremes();

        if (label === this.greatestRangeLabel) {
            // The user has selected the maximum time frame available to them. Extend the extremes
            // to the maximum time frame available.
            this.xAxis.setExtremes(chartMin, chartMax);
        } else {
            // Try to keep the current end (max), and extend the start (min) to the new range.
            // If the new start becomes earlier than the earliest minimum (chartMin), and start
            // at chartMin, and set the new end to be the duration of `label` past chartMin.
            const newMin = max - RANGE_DURATIONS[label];
            if (newMin < chartMin) {
                this.xAxis.setExtremes(
                    chartMin,
                    chartMin + RANGE_DURATIONS[label]
                );
            } else {
                this.xAxis.setExtremes(newMin, max);
            }
        }

        this.updateYAxisExtreme()
    }

    updateYAxisExtreme() {
        if (this.currentUpperYExtreme) {
            const newLow = Math.min(this.currentLowerYExtreme, this.chart.yAxis[0].dataMin)
            const newMax = Math.max(this.currentUpperYExtreme, this.chart.yAxis[0].dataMax)
            
            this.chart.yAxis[0].setExtremes(newLow, newMax)
        }
    }

    /**
     * Writes a batch of events to loadedEvents
     * 
     * @param {Array} events The list of events to set as the loaded events
     */
    setLoadedEvents(events) {
        this.loadedEvents = events;
        this.$rootScope.$applyAsync();
    }

    setCurrentExtremes(extremes) {
        this.currentExtremes = extremes;
        this.$rootScope.$applyAsync();
    }

    setMetadata(metadata) {
        this.metadata = metadata;
        this.onMetadataChanged(this.EventEmitter(metadata));
    }

    $onChanges(changes) {
        if (
            changes.eventsObservable &&
            changes.eventsObservable.currentValue &&
            changes.eventsObservable.currentValue !==
                changes.eventsObservable.previousValue
        ) {
            const events$ = changes.eventsObservable.currentValue;
            events$.subscribe(event => {
                if (
                    this.chart &&
                    this.getEventTypes().includes(event.eventType) &&
                    !this.metadata?.bucketSizeSeconds // Don't add events to the chart if we're aggregating
                ) {

                    // TODO: Instead of updating loadedEvents here, it should insert the event into the correct
                    // place in this.formattedData. This will be more efficient, since we won't have to re-process
                    // all the data twice. Need to make sure that offline plot bands are handled correctly in 
                    // the case we receive backfill events.
                   
                    // Find correct index to insert event
                    let insertIndex = this.loadedEvents.length - 1
                    const newEventTimestamp = new Date(event.timestamp).getTime()
                    for (let index = this.loadedEvents.length - 1; index >= 0; index--) {
                        const eventTimestamp = new Date(this.loadedEvents[index].timestamp).getTime()
                        if (eventTimestamp < newEventTimestamp) {
                            break
                        }
                        insertIndex = index
                    }
                    this.loadedEvents.splice(insertIndex, 0, event)

                    this.setLoadedEvents(this.loadedEvents);
                    if (event.eventType === this.eventType) {
                        this.onStateEventReceived(event.data[this.eventType]);
                        const { min, max, dataMax } = this.xAxis.getExtremes();
                        if (ConfigHelper.isCloseToAxisMax(min, max, dataMax)) {
                            this.updateSpacerSeries();
                        }
                    }
                }
            });
        }
        if (
            changes.range &&
            changes.range.currentValue !== changes.range.previousValue &&
            changes.range.currentValue
        ) {
            if (this.chart) {
                this.setRange(changes.range.currentValue);
            }
        }

        if (this.chart && this.thresholds) {
            if (changes.thresholds?.currentValue !== changes.thresholds?.previousValue) {
                this.addYAxisPlotLines()
            }
        }
    }

    addYAxisPlotLines() {

        const thresholdValues = this.thresholds.split(',')
        const lowerYExtreme = isNaN(thresholdValues[0]) ? this.chart.yAxis[0].dataMin : thresholdValues[0] 
        const upperYExtreme = isNaN(thresholdValues[1]) ? this.chart.yAxis[0].dataMax : thresholdValues[1]

        
        // Choose the correct Y-axis index based on the chart type
        const yAxisIndex = this.chartType === 'humidity' ? 2 : 0 

        if (thresholdValues[0] !== 'undefined') {
            this.chart.yAxis[yAxisIndex].removePlotLine('lower');
            this.chart.yAxis[yAxisIndex].addPlotLine({
                value: thresholdValues[0],
                width: 2,
                id: 'lower',
                className: 'threshold',
                label: {
                    text: 'Lower Limit',
                    align: 'right',
                    x: -10,
                    y: 14
                },
                zIndex: 10
            })
        }
        if (thresholdValues[1]) {
            this.chart.yAxis[yAxisIndex].removePlotLine('upper');
            this.chart.yAxis[yAxisIndex].addPlotLine({
                value: thresholdValues[1],
                width: 2,
                id: 'upper',
                className: 'threshold',
                label: {
                    text: 'Upper Limit',
                    align: 'right',
                    x: -10
                },
                zIndex: 10
            })
        }

        // Ensure both data and plot-lines are with the Y-axis extremes
        const newLowExtreme = Math.min(Math.min(lowerYExtreme, this.chart.yAxis[0].dataMin), upperYExtreme)
        const newMaxExtreme = Math.max(Math.max(upperYExtreme, this.chart.yAxis[0].dataMax), lowerYExtreme)
        this.chart.yAxis[0].setExtremes(newLowExtreme, newMaxExtreme)

        this.currentLowerYExtreme = newLowExtreme
        this.currentUpperYExtreme = newMaxExtreme
    }
}

export default AbstractChartController;
