/* @ngInject */
export default function AlertChipPatternDirective() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link($scope, $element, $attr, ngModel) {
            const ngPattern = ngModel.$validators.pattern;
            ngModel.$validators.pattern = function(modelValue, viewValue) {
                if (ngModel.$isEmpty(viewValue)) {
                    return false
                }
                const result = viewValue.filter(e => !ngPattern(modelValue, e));
                return result.length === 0;
            };
        },
    };
};
