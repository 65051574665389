
import moment from 'moment';
import { getTemperatureUnitSuffix, celsiusToFahrenheit, getHoursMinutesFormat } from 'services/utils';
import { RANGE_LABEL_WEEK, SENSOR_GRAPH_RANGES, ALERT_EVENT_TYPES } from 'services/charting/constants';
import { fields } from '../rules/rules-detail/rules-detail.controller';
import { Duration } from '../rules/rules-detail/duration/duration.controller';
import { States } from '../../../app.router';
import _cloneDeep from 'lodash/cloneDeep';
import Highcharts from 'highcharts';

import TimelineController from './timeline/timeline.controller';
import TimelineTemplate from './timeline/timeline.html';

import FilterController from './alert-log-filter-popover/controller';
import FilterTemplate from './alert-log-filter-popover/template.html';

/* @ngInject */
export default class ActiveAlertsController {
    
    constructor($scope, $state, $mdPanel, AlertsAndRulesService, SensorService, FeatureFlags, UserPreferencesManager, DialogService, ToastService) {
        this.$scope = $scope;
        this.$state = $state;
        this.$mdPanel = $mdPanel;
        this.AlertsAndRulesService = AlertsAndRulesService;
        this.SensorService = SensorService;
        this.FeatureFlags = FeatureFlags;
        this.UserPreferencesManager = UserPreferencesManager;
        this.DialogService = DialogService;
        this.ToastService = ToastService;

        this.updateFilters = this.updateFilters.bind(this)
        this.updateFilterCount = this.updateFilterCount.bind(this)
    }
    
    $onInit() {
        this.fields = fields;
        this.ranges = SENSOR_GRAPH_RANGES;
        this.loadedAlerts = false;
        this.alerts = [];
        this.alertCountLast90Days = 0;
        this.filters = [];
        this.filterCount = 0;
        this.query = '';
    
        // Create an array of the last 90 days
        this.days = Array.from({ length: 90 }, (_, i) => {
            return {
                alertCount: 0,
                alerts: [],
                date: moment().subtract(i, 'days'),
                dateFormatted: moment().subtract(i, 'days').format('MMM D, YYYY')
            };
        });
        this.days.reverse(); // Reverse the array so the most recent day is first
    
        const allAlerts = []; 
    
        // Function to fetch alerts with pagination
        const fetchAlerts = (pageToken = '') => {
            return this.AlertsAndRulesService.listAlertLog({ pageToken }).then(response => {
                const alerts = response.data;
                allAlerts.push(...alerts); // Append current page alerts to allAlerts
    
                if (response.nextPageToken) {
                    // More pages to fetch
                    return fetchAlerts(response.nextPageToken);
                } 
                // All pages fetched
                return Promise.resolve();
            });
        };
    
        // Start fetching alerts
        fetchAlerts().then(() => {
            // Process all fetched alerts
            allAlerts.forEach(alert => {                
                alert.triggered = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED).triggered;
                alert.triggerDescription = this.triggerDescription(alert);
                alert.currentRange = RANGE_LABEL_WEEK;
                alert.showOptionsDropdown = false;
                alert.createTimeFormatted = moment(alert.createTime).format(getHoursMinutesFormat());
    
                // If the alert was archived, get the archived event
                if (alert.status === 'ARCHIVED') {
                    alert.archivedTime = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ARCHIVED).createTime;
                }
            });
    
            const deviceIds = allAlerts.map(alert => alert.device.split('/')[3]);
    
            this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
                allAlerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.deviceId = alert.device.split('/')[3];
                    alert.device = device;
                    
                    // Check if createTime matches one of the days in the last 90 days array
                    const alertDate = moment(alert.createTime).format('YYYY-MM-DD');
                    const day = this.days.find(d => d.date.format('YYYY-MM-DD') === alertDate);
                    if (day) {
                        day.alerts.unshift(alert); // Add alert to the beginning of the day's alerts array
                    }
                });
                // Sort alerts by resolveTime or archivedTime
                allAlerts.sort((a, b) => {
                    return new Date(b.resolveTime || b.archivedTime) - new Date(a.resolveTime || a.archivedTime);
                });
                this.alerts = allAlerts;
                this.alertCountLast90Days = allAlerts.length;
                this.groupedAlertsByDay = this.groupAlertsByDay();
                this.loadedAlerts = true; 

                const ackData = [
                    40.99, 39.50, 60.85, 42.37, 38.63, 38.41, 41.81, 39.96, 37.26, 39.06,
                    36.83, 36.60, 37.79, 33.25, 33.40, 35.50, 34.38, 36.81, 34.14, 32.91,
                    38.44, 34.83, 35.19, 31.98, 33.52, 34.60, 31.86, 34.68, 32.51, 32.90,
                    32.06, 36.74, 32.78, 30.47, 34.00, 29.69, 32.33, 27.77, 28.80, 31.63,
                    32.49, 31.13, 30.33, 29.73, 27.16, 28.45, 28.74, 31.55, 29.90, 25.46,
                    29.41, 27.77, 26.96, 29.31, 29.93, 29.50, 25.74, 26.57, 27.63, 28.69,
                    25.56, 25.92, 23.85, 23.45, 27.24, 28.11, 25.02, 26.95, 25.44, 23.20,
                    24.99, 27.12, 23.75, 26.72, 18.13, 24.79, 23.10, 22.10, 22.66, 18.27,
                    21.58, 22.51, 24.53, 20.31, 19.51, 19.90, 22.50, 21.11, 19.17, 21.03
                ];

                const resolveData = [
                    40.99, 39.50, 80.85, 42.37, 38.63, 38.41, 41.81, 39.96, 37.26, 39.06,
                    36.83, 36.60, 37.79, 33.25, 33.40, 35.50, 34.38, 36.81, 34.14, 32.91,
                    38.44, 34.83, 35.19, 31.98, 33.52, 34.60, 31.86, 34.68, 32.51, 32.90,
                    32.06, 36.74, 32.78, 30.47, 34.00, 29.69, 32.33, 27.77, 28.80, 31.63,
                    32.49, 31.13, 30.33, 29.73, 27.16, 28.45, 28.74, 31.55, 29.90, 25.46,
                    29.41, 27.77, 26.96, 29.31, 29.93, 29.50, 25.74, 26.57, 27.63, 28.69,
                    25.56, 25.92, 23.85, 23.45, 27.24, 28.11, 25.02, 26.95, 25.44, 23.20,
                    24.99, 27.12, 23.75, 26.72, 18.13, 24.79, 23.10, 52.10, 22.66, 18.27,
                    21.58, 22.51, 24.53, 20.31, 19.51, 19.90, 22.50, 21.11, 19.17, 21.03
                ];


                setTimeout(() => {
                    this.initializeChart('chart1', 'Time to Acknowledge', ackData);
                    this.initializeChart('chart2', 'Time to Resolve', resolveData);
                }, 100);
                this.$scope.$applyAsync();
            });  
        });
    }

    groupAlertsByDay() {
        // Group alerts by day
        const groupedAlerts = [];
        const now = moment();
        this.alerts.forEach(alert => {
            
            let alertDate = moment(alert.createTime)
            if (now.diff(alertDate, 'days') <= 6) {
                alertDate = alertDate.calendar(null, {
                    sameDay: `[Today], MMMM D, YYYY`,
                    lastDay: `[Yesterday], MMMM D, YYYY`,
                    lastWeek: 'dddd, MMMM D, YYYY',
                    sameElse: 'dddd, MMMM D, YYYY'
                });
            } else {
                alertDate = moment(alert.createTime).format('dddd, MMMM D, YYYY');
            }

            const day = groupedAlerts.find(d => d.date === alertDate);
            if (day) {
                day.alerts.push(alert);
            } else {
                groupedAlerts.push({
                    date: alertDate,
                    alerts: [alert]
                });
            }
        });
        return groupedAlerts;
    }

    // Highcharts initialization
    initializeChart(id, displayName, data) {
          

        Highcharts.chart(id, {
            chart: {
                type: 'line',
                height: 74,
                marginLeft: 44,
                marginBottom: 30,
                reflow: true
                              
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                tickInterval: 30 * 24 * 3600 * 1000, // One tick per month
                labels: {
                    format: '{value:%b}' // Display short month name
                },
                tickLength: 0,
                lineWidth: 0
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    format: '{value}'
                },
                min: 0,
                max: Math.max(...data) + 5, // Adjust max dynamically based on data
                tickInterval: Math.ceil(Math.max(...data) / 3),
                
            },
            legend: {
                enabled: false
            },
            tooltip: {
                enabled: true,
                followPointer: true,
                outside: true,
                shadow: false,
                animation: false,
                hideDelay: 100,
                borderRadius: 12,
                padding: 10,
                shared: true,
                useHTML: true,
                formatter() {
                    const momentFormat = `dddd, MMM D`;
                    const timestamp = moment(this.x).format(momentFormat);
                    let tooltipHtml = `
                        <p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">
                            ${timestamp}
                        </p>`
                    tooltipHtml += "<table>"
                    tooltipHtml += `
                        <tr>
                            <td style="color: white; padding-right: 12px;">
                                ${displayName}
                            </td>
                            <td style="font-weight:bold; text-align: right; color: white">
                                ${Math.round(this.y)} Minutes
                            </td>
                        </tr>`

                    tooltipHtml += "</table>";
                    return tooltipHtml;
                }
            },
            series: [{
                name: 'Alerts',
                data: data,
                pointStart: Date.UTC(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()), // 90 days ago
                pointInterval: 24 * 3600 * 1000, // One data point per day
                lineWidth: 2,
                color: '#3BA272',
                marker: {
                    enabled: false
                }
            }],
            credits: {
                enabled: false
            }
        });
    }

    getTriggerTime(alert, format = '') {
        // Get the time the alert was triggered
        const triggeredEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED);
        if (format === 'dateOnly') {
            return triggeredEvent ? moment(triggeredEvent.createTime).format('MMM D') : 'N/A';
        } else if (format === 'timeOnly') { 
            return triggeredEvent ? moment(triggeredEvent.createTime).format(getHoursMinutesFormat()) : 'N/A';
        }
        return triggeredEvent ? moment(triggeredEvent.createTime).format(`MMM D, ${getHoursMinutesFormat()}`) : 'N/A';
    }

    getResolveTime(alert, format = '') {
        // Get the time the alert was resolved or archived
        const timestamp = moment(alert.resolveTime || alert.archivedTime);
        
        if (format === 'dateOnly') {
            return timestamp.format('MMM D');
        } else if (format === 'timeOnly') { 
            return timestamp.format(getHoursMinutesFormat());
        }

        // Return the time in a human readable format, but keep it relative if it was solved in the past 5 days
        const now = moment();
        if (now.diff(timestamp, 'days') <= 4) {
            // Eg. "Monday at 3:00 PM"
            return timestamp.calendar(null, {
                sameDay: `[Today] ${getHoursMinutesFormat()}`,
                lastDay: `[Yesterday] ${getHoursMinutesFormat()}`,
                lastWeek: `dddd ${getHoursMinutesFormat()}`,
                sameElse: `MMM D ${getHoursMinutesFormat()}`
            });
        }
        return timestamp.format(`MMM D, ${getHoursMinutesFormat()}`);
    }


    getDuration(alert, asMinutes = false) {
        // Get the duration of the alert
        const triggeredEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED);
        const resolvedEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.RESOLVED);
        const archivedEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ARCHIVED);
        if (triggeredEvent && (resolvedEvent || archivedEvent)) {
            const resolvedTime = resolvedEvent ? resolvedEvent.createTime : archivedEvent.createTime;
            const duration = moment(resolvedTime).diff(moment(triggeredEvent.createTime));
            if (asMinutes) {
                return Math.round(moment.duration(duration).asMinutes());
            }
            // Use moment to get the duration in human readable format
            let humanizedDuration = moment.duration(duration).humanize();
            if (humanizedDuration === 'a few seconds') {
                humanizedDuration = '< 1 minute';
            }
            return humanizedDuration;
        }
        return 'N/A';
    }

    alertHasBeenAcknowledged(alert) {
        return alert.events.some(event => event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED);
    }

    acknowledgedByUser(alert) {
        const acknowledgedEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED);
        return acknowledgedEvent ? acknowledgedEvent.acknowledged.account.displayName : '';
    }

    alertHasCorrectiveAction(alert) {
        return alert.events.some(event => event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION);
    }

    correctiveActionByUser(alert) {
        const correctiveActionEvent = alert.events.find(event => event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION);
        return correctiveActionEvent ? correctiveActionEvent.correctiveAction.account.displayName : '';
    }

    alertIcon(alert) {
        let iconName = alert.triggered.trigger.field
        if (iconName === 'relativeHumidity') {
            iconName = 'humidity'
        }
        if (iconName === 'connectionStatus') {
            iconName = alert.device.typeIcon
        }
        return iconName
    }

    showTimeline(alert) {
        this.DialogService.show({
            controller: TimelineController,
            template: TimelineTemplate,
            controllerAs: '$ctrl',
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: false,
            locals: {
                alert
            }
        })
    }

    getNumberOfUserInteractions(alert) {
        // Get the number of user interactions (acknowledgements, corrective actions, comments)
        return alert.events.filter(event => {
            return event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED || 
                   event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION || 
                   event.type === ALERT_EVENT_TYPES.COMMENT;
        }).length;
    }

    convertEventsForTimeline(alert) {
        // Convert createTime to human readable format
        alert.events.forEach(event => {
            event.createTimeFormatted = moment(event.createTime).format(`MMM D, ${getHoursMinutesFormat()}`);
            event.timeSince = moment(event.createTime).fromNow();

            // Convert type to a more friendly format
            switch (event.type) {
                case ALERT_EVENT_TYPES.TRIGGERED:
                    event.typeFormatted = 'Open - Active Alert';
                    break;
                case ALERT_EVENT_TYPES.ALERT_DELIVERY_SUCCEEDED:
                    if (event.alertDeliverySucceeded.action.type === 'EMAIL') {
                        event.typeFormatted = `Sent ${event.alertDeliverySucceeded.action.type} to ${event.alertDeliverySucceeded.action.email.recipients[0]}`;
                    } 
                    if (event.alertDeliverySucceeded.action.type === 'SMS') {
                        event.typeFormatted = `Sent ${event.alertDeliverySucceeded.action.type} ${event.alertDeliverySucceeded.action.sms.recipients[0]}`;
                    }
                    break;
                case ALERT_EVENT_TYPES.ALERT_DELIVERY_FAILED:
                    event.typeFormatted = `Failed to deliver ${event.alertDeliveryFailed.action.type}`;
                    break;
                case ALERT_EVENT_TYPES.ACKNOWLEDGED:
                    event.typeFormatted = `Acknowledged by ${event.acknowledged.account.displayName}`;
                    break;
                case ALERT_EVENT_TYPES.CORRECTIVE_ACTION:
                    event.typeFormatted = 'Corrective Action';
                    break;
                case ALERT_EVENT_TYPES.COMMENT:
                    event.typeFormatted = `Comment by ${event.comment.account.displayName}`;
                    break;
                case ALERT_EVENT_TYPES.RESOLVED:
                    event.typeFormatted = 'Resolved';
                    break;
                case ALERT_EVENT_TYPES.ARCHIVED:
                    if (event.archived.description === 'Archived by user') {
                        event.typeFormatted = `Archived by ${event.archived.account.displayName}`;
                    } else {
                        event.typeFormatted = event.archived.description;
                    }
                    break;
                default:
                    event.typeFormatted = event.type;
                    break
            }
        })        

    }

    triggerDescription(alert) {
        // Returns a clear and explicit trigger description
        // E.g. 'Humidity above 15%' or 'No water present for 5 minutes'
        const trigger = alert.triggered.trigger;
        const measurement = this.fields[trigger.field].displayName;

        const unit = trigger.field === "temperature" ? getTemperatureUnitSuffix() : this.fields[trigger.field].unit

        const humanizedDelay = Duration.humanizedFromString(alert.triggered.triggerDelay);
        const delayString = humanizedDelay ? ` for ${humanizedDelay}` : '';

        // If the upper/lower range exists, the field is temperature, and the user wants fahrenheit,
        // convert the value to fahrenheit. Otherwise leave it as-is.
        let lowerRange = trigger.range?.lower;
        let upperRange = trigger.range?.upper;
        if (lowerRange !== null && trigger.field === "temperature" && this.UserPreferencesManager.useFahrenheit) {
            lowerRange = celsiusToFahrenheit(lowerRange).toFixed(1);
        }
        if (upperRange !== null && trigger.field === "temperature" && this.UserPreferencesManager.useFahrenheit) {
            upperRange = celsiusToFahrenheit(upperRange).toFixed(1);
        }
        
        switch (trigger.field) {
            case 'touch':
                return `${this.fields[trigger.field].displayName}${delayString}`;
            case 'temperature':
            case 'co2':
            case 'relativeHumidity':
                switch (trigger.range.type) {
                    case 'OUTSIDE':
                        if (trigger.range.lower !== null && trigger.range.upper !== null) {
                            return `${measurement} outside range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower !== null && trigger.range.upper === null) {
                            return `${measurement} below ${lowerRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower === null && trigger.range.upper !== null) {
                            return `${measurement} above ${upperRange}${unit}${delayString}`;
                        }
                        return 'No trigger';
                    case 'WITHIN':
                        if (trigger.range.lower !== null && trigger.range.upper !== null) {
                            return `${measurement} within range ${lowerRange}${unit} and ${upperRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower !== null && trigger.range.upper === null) {
                            return `${measurement} above ${lowerRange}${unit}${delayString}`;
                        }
                        if (trigger.range.lower === null && trigger.range.upper !== null) {
                            return `${measurement} below ${upperRange}${unit}${delayString}`;
                        }
                        return 'No trigger';
                    default: 
                        return 'No trigger';
                }
            case 'contact':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.contact]}${delayString}`;
            case 'objectPresent':
            case 'waterPresent':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.presence]}${delayString}`;
            case 'motion':
                return `${this.fields[trigger.field].operatorDisplayNames[trigger.motion]}${delayString}`;
            case 'touchCount':
                return `Touch count reaches ${trigger.triggerCount}`
            case 'proximityCount':
                return `Proximity count reaches ${trigger.triggerCount}`
            case 'connectionStatus':
                switch (trigger.connection) {
                    case 'CLOUD_CONNECTOR_OFFLINE':
                        return 'Cloud Connector offline';
                    case 'SENSOR_OFFLINE':
                        return 'Sensor offline';
                    default:
                        return 'No trigger';
                }
            default:
                return measurement;
        }
    }

    searchAlertLog() {
        const allAlerts = []; // Array to store all fetched alerts
    
        // Function to fetch alerts with pagination
        const fetchSearchAlerts = (pageToken = '') => {
            // Prepare parameters for the API call
            const params = { searchQuery: this.query };
            params.statuses =  ['RESOLVED', 'ARCHIVED'];
            this.filters.forEach(filter => {
                if (filter.active) {

                    if (filter.displayName === 'Status') {
                        params.statuses = filter.options.filter(option => option.active).map(option => option.value);
                        if (params.statuses.length === 0) {
                            params.statuses = ['RESOLVED', 'ARCHIVED'];
                        }
                    }

                    if (filter.displayName === 'Date Range') {
                        params.startTime = filter.options.find(option => option.value === filter.optionValue).startTime;
                        params.endTime = filter.options.find(option => option.value === filter.optionValue).endTime;
                    }
                }
            })
            if (pageToken) {
                params.pageToken = pageToken;
            }
    
            // Call the searchAlertLog method with query and pageToken
            return this.AlertsAndRulesService.searchAlertLog(params).then(response => {
                const alerts = response.data; // Adjust based on response structure
                allAlerts.push(...alerts); // Append current page alerts to allAlerts
    
                if (response.nextPageToken) {
                    // More pages to fetch
                    return fetchSearchAlerts(response.nextPageToken);
                } 
                // All pages fetched
                return Promise.resolve();
            });
        };
    
        // Start fetching alerts
        fetchSearchAlerts().then(() => {
            // Process all fetched alerts
            allAlerts.forEach(alert => {                
                alert.triggered = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED).triggered;
                alert.triggerDescription = this.triggerDescription(alert);
                alert.currentRange = RANGE_LABEL_WEEK;
                alert.showOptionsDropdown = false;
                alert.createTimeFormatted = moment(alert.createTime).format(getHoursMinutesFormat());
    
                // If the alert was archived, get the archived event
                if (alert.status === 'ARCHIVED') {
                    alert.archivedTime = alert.events.find(event => event.type === ALERT_EVENT_TYPES.ARCHIVED).createTime;
                }
            });
    
            const deviceIds = allAlerts.map(alert => alert.device.split('/')[3]);
    
            this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
    
                allAlerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.deviceId = alert.device.split('/')[3];
                    alert.device = device;
                });
    
                // Sort alerts by resolveTime or archivedTime
                allAlerts.sort((a, b) => {
                    return new Date(b.resolveTime || b.archivedTime) - new Date(a.resolveTime || a.archivedTime);
                });
    
                this.alerts = allAlerts;
                this.groupedAlertsByDay = this.groupAlertsByDay();
                this.loadedAlerts = true;                
                this.$scope.$applyAsync();
            });  
        });
    }
    

    showFilterPopover() {
        const button = document.getElementById('alertFilterButton')
        const position = this.$mdPanel.newPanelPosition()
            .relativeTo(button)
            .addPanelPosition(this.$mdPanel.xPosition.ALIGN_END , this.$mdPanel.yPosition.BELOW).withOffsetY('12px');;
            
        const panelAnimation = this.$mdPanel.newPanelAnimation()
            .openFrom(button)
            .withAnimation('md-panel--animation');

        const config = {
            attachTo: document.body,
            controller: FilterController,
            controllerAs: '$ctrl',
            template: FilterTemplate,
            panelClass: 'md-panel-container',
            animation: panelAnimation,
            position,
            openFrom: button,
            clickOutsideToClose: true,
            escapeToClose: true,
            onRemoving: this.closeFilterPopover.bind(this),
            focusOnOpen: true,
            zIndex: 100,
            locals: {
                filters: _cloneDeep(this.filters),
                onUpdateFilterCount: this.updateFilterCount.bind(this),
                onUpdateFilters: this.updateFilters.bind(this),
                onClose: this.closeFilterPopover.bind(this)
            }
        };

        this.$mdPanel.open(config).then(reference => {
            this.panelRef = reference;
        });
    }

    updateFilterCount(count) {
        this.filterCount = count
    }

    updateFilters(filters) {
        this.filters = filters
        this.searchAlertLog()
    }

    clearFilters() {
        this.filters = []
        this.filterCount = 0
        this.panelRef.close()
        this.searchAlertLog()

    }

    closeFilterPopover() {
        this.panelRef.close()
    }

    exportAlertLog() {
        
        
        // Creates a CSV based on the alerts and downloads it automatically 
        let csv = 'Alert Name,Alert ID,Alert Trigger,Alert Status,Device Name,Device ID,Create Time,Resolve Time,Duration,Duration (minutes),Has Been Acknowledged,Acknowledged by,Has Corrective Action,Corrective Action by\r\n';

        this.alerts.forEach(alert => {
            const acknowledgedBy = this.acknowledgedByUser(alert);
            const correctiveActionBy = this.correctiveActionByUser(alert);
            const durationMinutes = this.getDuration(alert, true);
            const durationReadable = this.getDuration(alert);
            csv += `${alert.displayName},${alert.id},${alert.triggerDescription},${alert.status},${alert.device.labels.name},${alert.deviceId},${alert.createTime},${alert.resolveTime || ''},${durationReadable},${durationMinutes},${alert.hasBeenAcknowledged},${acknowledgedBy},${alert.hasCorrectiveAction},${correctiveActionBy}\r\n`;
        });

        const exportedFilename = 'alert_log.csv'
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        this.ToastService.showSimpleTranslated('alert_log_exported');
        
    }

    goToDevice(device) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: device.name.split('/')[1],
            sensorId: device.name.split('/')[3]
        });
    }

    goToAlertRule(alert) {
        this.$state.go(States.ALERTS_RULES_DETAIL, {
            ruleId: alert.rule.split('/')[3]
        });
    }
 
    $onDestroy() {}

}
