import moment from 'moment';
import _cloneDeep from 'lodash/cloneDeep';

/* @ngInject */
export default class ProjectFilterController {
    constructor($scope, filters, onClose) {
        this.$scope = $scope
        this.filters = filters
        this.onClose = onClose;
    }

    $onInit() {
        this.setupFilters()
    }

    close() {
        this.onClose()
    }

    updateCount() {
        let count = 0
        Object.keys(this.filters).forEach(key => {
            if (this.filters[key].active) {
                count += 1
            }
        });
        this.onUpdateFilterCount(count)
    }

    update() {
        this.updateCount()
        // Check if any filter optionValues are null, if so, don't update
        // This is caused by the <select> element being reset when the element is removed from the DOM
        if (this.filters.filter(filter => filter.optionValue === null).length === 0) {
            this.onUpdateFilters(_cloneDeep(this.filters))
        }
    }

    setupFilters() {
        
        if (this.filters.length === 0) {
            this.filters.push(
                {
                    displayName: "Status",
                    active: false,
                    options: [
                        {
                            displayName: "Resolved",
                            value: "RESOLVED",
                            active: true
                        },
                        {
                            displayName: "Archived",
                            value: "ARCHIVED",
                            active: true
                        }
                    ],
                },
                {
                    displayName: "Date Range",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: 'Today',
                            value: 'today',
                            startTime: moment().startOf('day').format(),
                            endTime: moment().format()
                        },
                        {
                            displayValue: 'Yesterday',
                            value: 'yesterday',
                            startTime: moment().subtract(1, 'days').startOf('day').format(),
                            endTime: moment().subtract(1, 'days').endOf('day').format()
                        },
                        {
                            displayValue: 'Last 7 days',
                            value: '7_days',
                            startTime: moment().subtract(6, 'days').startOf('day').format(),
                            endTime: moment().format()
                        },
                        {
                            displayValue: 'Last 30 days',
                            value: '30_days',
                            startTime: moment().subtract(29, 'days').startOf('day').format(),
                            endTime: moment().format()
                        },
                        {
                            displayValue: 'Last 90 days',
                            value: '90_days',
                            startTime: moment().subtract(89, 'days').startOf('day').format(),
                            endTime: moment().format()
                        },
                        {
                            displayValue: `Current Month (${moment().format('MMM 1')} - ${moment().format('MMM D')})`,
                            value: 'current_month',
                            startTime: moment().startOf('month').format(),
                            endTime: moment().format()
                        },
                        {
                            displayValue: `Last Month (${moment().subtract(1, 'months').format('MMM 1')} - ${moment().subtract(1, 'months').endOf('month').format('MMM D')})`,
                            value: 'last_month',
                            startTime: moment().subtract(1, 'months').startOf('month').format(),
                            endTime: moment().subtract(1, 'months').endOf('month').format()
                        },
                        {
                            displayValue: "Custom",
                            value: 'custom',
                            // startTime and endTime will be set based on user input
                        }
                    ],
                    optionValue: '7_days'
                },
                {
                    displayName: "Device Labels",
                    active: false,
                    dropdown: true,
                    options: [],
                    optionValue: "",
                    disabled: true
                },
                {
                    displayName: "Duration",
                    active: false,
                    dropdown: true,
                    options: [],
                    optionValue: "",
                    disabled: true
                },
                {
                    displayName: "Acknowledged",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "Has been acknowledged",
                            value: "On"
                        },
                        {
                            displayValue: "Has not been acknowledged",
                            value: "Off"
                        }
                    ],
                    optionValue: "On",
                    disabled: true
                },
                {
                    displayName: "Corrective Action",
                    active: false,
                    dropdown: true,
                    options: [
                        {
                            displayValue: "Has corrective action",
                            value: "On"
                        },
                        {
                            displayValue: "No corrective action",
                            value: "Off"
                        }
                    ],
                    optionValue: "On",
                    disabled: true
                },
            )
        }
    }
}
