/* eslint-disable */

// Material icons
export const addCircleIcon = {
    name: 'add_circle',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_add_circle_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_add_circle_24px.svg').default
};

export const adminIcon = {
    name: 'admin',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_admin_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_admin_24px.svg').default
};

export const arrowBackIcon = {
    name: 'arrow_back',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_arrow_back_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_arrow_back_24px.svg').default
};

export const arrowForwardIcon = {
    name: 'arrow_forward',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_arrow_forward_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_arrow_forward_24px.svg').default
};

export const arrowDropDownIcon = {
    name: 'arrow_drop_down',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_arrow_drop_down_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_arrow_drop_down_24px.svg').default
};

export const arrowDropUpIcon = {
    name: 'arrow_drop_up',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_arrow_drop_up_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_arrow_drop_up_24px.svg').default
};

export const assignmentIcon = {
    name: 'assignment',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_assignment_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_assignment_24px.svg').default
};

export const backgroundUpdate = {
    name: 'background_update',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_background_update_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_background_update_24px.svg').default
};

export const batteryFullIcon = {
    name: 'battery_full',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_battery_full_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_battery_full_24px.svg').default
};

export const batteryUnknownIcon = {
    name: 'battery_unknown',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_battery_unknown_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_battery_unknown_24px.svg').default
};

export const blockIcon = {
    name: 'block',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_block_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_block_24px.svg').default
};

export const cancelIcon = {
    name: 'cancel',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_cancel_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_cancel_24px.svg').default
};

export const calendarIcon = {
    name: 'calendar',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_calendar_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_calendar_24px.svg').default
};

export const calibratedBadge = {
    name: 'calibrated_badge',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_calibrated_badge_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_calibrated_badge_24px.svg').default
};

export const checkCircleIcon = {
    name: 'check_circle',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_check_circle_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_check_circle_24px.svg').default
};

export const chevronLeftIcon = {
    name: 'chevron_left',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_chevron_left_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_chevron_left_24px.svg').default
};

export const chevronRightIcon = {
    name: 'chevron_right',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_chevron_right_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_chevron_right_24px.svg').default
};


export const clearIcon = {
    name: 'clear',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_clear_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_clear_24px.svg').default
};

export const co2Icon = {
    name: 'co2',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_co2_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_co2_24px.svg').default
};

export const creditCardIcon = {
    name: 'credit_card',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_credit_card.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_credit_card.svg').default
};

export const dashboardIcon = {
    name: 'dashboard',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_dashboard.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_dashboard.svg').default
};

export const docsIcon = {
    name: 'docs',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_docs_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_docs_24px.svg').default
};

export const contentCopyIcon = {
    name: 'content_copy',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_content_copy_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_content_copy_24px.svg').default
};

export const editCircleIcon = {
    name: 'edit_circle',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_edit_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_edit_24px.svg').default
};

export const emulatorIcon = {
    name: 'emulator',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_emulator.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_emulator.svg').default
};

export const errorIcon = {
    name: 'error',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_error_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_error_24px.svg').default
};

export const fileExportIcon = {
    name: 'file_export',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_file_export.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_file_export.svg').default
};

export const filterIcon = {
    name: 'filter',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_filter_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_filter_24px.svg').default
};

export const groupIcon = {
    name: 'group',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_group_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_group_24px.svg').default
};

export const headerInfo = {
    name: 'header_info',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_header_info_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_header_info_24px.svg').default
};

export const headerUser = {
    name: 'header_user',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_header_user_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_header_user_24px.svg').default
};

export const heatmapIcon = {
    name: 'heatmap',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_heatmap_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_heatmap_24px.svg').default
};

export const helpIcon = {
    name: 'help',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_help_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_help_24px.svg').default
};

export const histogramIcon = {
    name: 'histogram',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_histogram_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_histogram_24px.svg').default
};

export const ciruclarHelpIcon = {
    name: 'circular_help',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_circular_help_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_circular_help_24px.svg').default
};

export const infoIcon = {
    name: 'info',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_info_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_info_24px.svg').default
};

export const insertLinkIcon = {
    name: 'insert_link',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_insert_link_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_insert_link_24px.svg').default
};

export const invoiceIcon = {
    name: 'invoice',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_invoice_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_invoice_24px.svg').default
};

export const openLinkIcon = {
    name: 'open_link',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_open_link_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_open_link_24px.svg').default
};

export const organizationIcon = {
    name: 'organization',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_organization_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_organization_24px.svg').default
};

export const organizationViewerIcon = {
    name: 'organization_viewer',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_organization_viewer_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_organization_viewer_24px.svg').default
};

export const feedbackIcon = {
    name: 'feedback',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_feedback_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_feedback_24px.svg').default
};

export const libraryBooksIcon = {
    name: 'library_books',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_library_books_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_library_books_24px.svg').default
};

export const listIcon = {
    name: 'list',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_list.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_list.svg').default
};

export const menuIcon = {
    name: 'menu',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_menu_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_menu_24px.svg').default
};

export const membersIcon = {
    name: 'members',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_members.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_members.svg').default
};

export const moreVertIcon = {
    name: 'more_vert',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_more_vert_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_more_vert_24px.svg').default
};

export const moveDeviceIcon = {
    name: 'move_device',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_move_device_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_move_device_24px.svg').default
};

export const newsIcon = {
    name: 'news',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_news.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_news.svg').default
};

export const openWithIcon = {
    name: 'open_with',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_open_with_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_open_with_24px.svg').default
};

export const personIcon = {
    name: 'person',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_person_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_person_24px.svg').default
};

export const phoneIcon = {
    name: 'phone',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_phone.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_phone.svg').default
};

export const refreshIcon = {
    name: 'refresh',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_refresh_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_refresh_24px.svg').default
};

export const removeRedEyeIcon = {
    name: 'remove_red_eye',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_remove_red_eye_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_remove_red_eye_24px.svg').default
};

export const renewIcon = {
    name: 'renew',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_renew_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_renew_24px.svg').default
};

export const replayIcon = {
    name: 'replay',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_replay_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_replay_24px.svg').default
};

export const reportIcon = {
    name: 'report',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_report_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_report_24px.svg').default
};

export const searchIcon = {
    name: 'search',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_search_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_search_24px.svg').default
};

export const sendIcon = {
    name: 'send',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_send_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_send_24px.svg').default
};

export const storeIcon = {
    name: 'store',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_store_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_store_24px.svg').default
};

export const timerIcon = {
    name: 'timer',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_timer_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_timer_24px.svg').default
};

export const timeSeriesIcon = {
    name: 'time_series',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_timeseries_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_timeseries_24px.svg').default
};

export const trashIcon = {
    name: 'trash',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_trash_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_trash_24px.svg').default
};

export const scheduleIcon = {
    name: 'schedule',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_schedule_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_schedule_24px.svg').default
};

export const unfoldIcon = {
    name: 'unfold',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_unfold_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_unfold_24px.svg').default
};

export const closeIcon = {
    name: 'close',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_close_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_close_24px.svg').default
};

export const visibilityOnIcon = {
    name: 'visibility_on',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_visibility_on_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_visibility_on_24px.svg').default
};

export const visibilityOffIcon = {
    name: 'visibility_off',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_visibility_off_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_visibility_off_24px.svg').default
};

export const vpnKeyIcon = {
    name: 'vpn_key',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_vpn_key_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_vpn_key_24px.svg').default
};

export const warningIcon = {
    name: 'warning',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_warning_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_warning_24px.svg').default
};

export const warningRoundedIcon = {
    name: 'warning_rounded',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_warning_rounded_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_warning_rounded_24px.svg').default
};

export const workIcon = {
    name: 'work',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_work_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_work_24px.svg').default
};

export const workOutlineIcon = {
    name: 'work_outline',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_work_outline_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_work_outline_24px.svg').default
};

export const workOutlineThinIcon = {
    name: 'work_outline_thin',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_work_outline_thin_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_work_outline_thin_24px.svg').default
};

export const labelOutlineIcon = {
    name: 'label_outline',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_label_outline_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_label_outline_24px.svg').default
};

export const notificationsIcon = {
    name: 'notifications',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_notifications_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_notifications_24px.svg').default
};

export const labelOutlineThinIcon = {
    name: 'label_outline_thin',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_label_outline_thin_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_label_outline_thin_24px.svg').default
};

export const qrIcon = {
    name: 'qr',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_qr_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_qr_24px.svg').default
};

export const xrangeIcon = {
    name: 'xrange',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/material/ic_xrange_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/material/ic_xrange_24px.svg').default
};

export const corrigoIcon = {
    name: 'corrigo',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/corrigo_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/corrigo_24px.svg').default
}

export const serviceChannelIcon = {
    name: 'service_channel',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/service_channel_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/service_channel_24px.svg').default
}

// From https://material.io/resources/icons
export const textSMSIcon = {
    name: 'textsms',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/textsms-24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/textsms-24px.svg').default
}

export const emailIcon = {
    name: 'email',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/email-24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/email-24px.svg').default
}

export const httpIcon = {
    name: 'http',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/http-24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/http-24px.svg').default
}

export const editIcon = {
    name: 'edit',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/edit-24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/edit-24px.svg').default
}

export const deleteIcon = {
    name: 'delete',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/delete-24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/delete-24px.svg').default
}

// DT Custom Icons
export const dtSensorBlankIcon = {
    name: 'sensor-blank',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/blank-sensor.svg'),
    src: require('raw-loader!../../assets/icons/svg/blank-sensor.svg').default
};

export const dtTemperatureIcon = {
    name: 'temperature',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/temperature.svg'),
    src: require('raw-loader!../../assets/icons/svg/temperature.svg').default
};

export const dtHumidityIcon = {
    name: 'humidity',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/humidity.svg'),
    src: require('raw-loader!../../assets/icons/svg/humidity.svg').default
};

export const dtDeskOccupancyIcon = {
    name: 'deskOccupancy',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/deskOccupancy.svg'),
    src: require('raw-loader!../../assets/icons/svg/deskOccupancy.svg').default
};

export const dtProximityCounterIcon = {
    name: 'proximityCounter',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/proximityCounter.svg'),
    src: require('raw-loader!../../assets/icons/svg/proximityCounter.svg').default
};

export const dtTouchCounterIcon = {
    name: 'touchCounter',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/touchCounter.svg'),
    src: require('raw-loader!../../assets/icons/svg/touchCounter.svg').default
};

export const dtWaterDetectorIcon = {
    name: 'waterDetector',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/waterDetector.svg'),
    src: require('raw-loader!../../assets/icons/svg/waterDetector.svg').default
};

export const dtCloudConnectorIcon = {
    name: 'cloud-connector',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ccon.svg'),
    src: require('raw-loader!../../assets/icons/svg/ccon.svg').default
};

export const dtCloudConnectorV2Icon = {
    name: 'cloud-connector-v2',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ccon-v2.svg'),
    src: require('raw-loader!../../assets/icons/svg/ccon-v2.svg').default
};

export const dtCloudConnectorFilledIcon = {
    name: 'cloud-connector-filled',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_ccon_filled_24px.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_ccon_filled_24px.svg').default
};

export const dtProximityIcon = {
    name: 'proximity',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/proximity.svg'),
    src: require('raw-loader!../../assets/icons/svg/proximity.svg').default
};

export const dtContactIcon = {
    name: 'contact',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/contact.svg'),
    src: require('raw-loader!../../assets/icons/svg/contact.svg').default
};

export const dtMotionIcon = {
    name: 'motion',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/motion.svg'),
    src: require('raw-loader!../../assets/icons/svg/motion.svg').default
};

export const dtTouchIcon = {
    name: 'touch',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/button.svg'),
    src: require('raw-loader!../../assets/icons/svg/button.svg').default
};

// TILT-SUPPORT
export const dtTiltIcon = {
    name: 'tilt',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/tilt.svg'),
    src: require('raw-loader!../../assets/icons/svg/tilt.svg').default
};

export const dtProbeTemperatureIcon = {
    name: 'probe',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/probe-temperature.svg'),
    src: require('raw-loader!../../assets/icons/svg/probe-temperature.svg').default
};

export const dtAnalogIcon = {
    name: 'analog',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/analog.svg'),
    src: require('raw-loader!../../assets/icons/svg/analog.svg').default
}

export const dtTableSortable = {
    name: 'table-sortable',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/table-sortable.svg'),
    src: require('raw-loader!../../assets/icons/svg/table-sortable.svg').default
};

export const dtTableSortedAsc = {
    name: 'table-sorted',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/table-sorted-asc.svg'),
    src: require('raw-loader!../../assets/icons/svg/table-sorted-asc.svg').default
};

export const dtDataConnectorIcon = {
    name: 'dt_data_connector',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_dt_data_connector.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_dt_data_connector.svg').default
};

export const dtApiIcon = {
    name: 'dt_api',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_dt_api.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_dt_api.svg').default
};

export const dtSettingsIcon = {
    name: 'dt_settings',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_dt_settings.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_dt_settings.svg').default
};

export const dtSensorIcon = {
    name: 'dt_sensor',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_dt_sensor.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_dt_sensor.svg').default
};

export const dtDashboardIcon = {
    name: 'dt_dashboard',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_dt_dashboard.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_dt_dashboard.svg').default
};

export const dtFullscreenIcon = {
    name: 'dt_fullscreen',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_dt_fullscreen.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_dt_fullscreen.svg').default
};

export const dtCO2AutoZero = {
    name: 'dt_co2_auto_zero',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_CO2_auto_zero.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_CO2_auto_zero.svg').default
};

export const dtExclamationMark = {
    name: 'dt_exclamation_mark',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_alert.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_alert.svg').default
};

export const dtExclamationMarkWarning = {
    name: 'dt_exclamation_mark_warning',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_warning.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_warning.svg').default
};

export const dtAlertOutline = {
    name: 'dt_alert_outline',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/alert-outline.svg'),
    src: require('raw-loader!../../assets/icons/svg/alert-outline.svg').default
};

export const dtSmallAlert = {
    name: 'small_alert',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_small_alert.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_small_alert.svg').default
};

export const dtSmallAck = {
    name: 'small_ack',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_small_ack.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_small_ack.svg').default
};

export const dtSmallCorrectiveAction = {
    name: 'small_corrective_action',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_small_corrective_action.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_small_corrective_action.svg').default
};

export const dtSmallUser = {
    name: 'small_user',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_small_user.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_small_user.svg').default
};

export const dtGraph = {
    name: 'graph',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_graph.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_graph.svg').default
};

export const dtComment = {
    name: 'comment',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_comment.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_comment.svg').default
};

export const dtSmallResolved = {
    name: 'small_resolved',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_small_resolved.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_small_resolved.svg').default
};

export const dtResolved = {
    name: 'resolved',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_resolved_big.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_resolved_big.svg').default
};

export const dtArchived = {
    name: 'archived',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_archived_big.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_archived_big.svg').default
};

export const dtSmallArchived = {
    name: 'small_archived',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_small_archived.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_small_archived.svg').default
};

export const dtEscalate = {
    name: 'escalate',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_escalate.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_escalate.svg').default
};

export const dtBoostWarning = {
    name: 'dt_boost_warning',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_warning_boost.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_warning_boost.svg').default
};

export const dtProjectInaccessibleIcon = {
    name: 'dt_project_inaccessible',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/project-inaccessible.svg'),
    src: require('raw-loader!../../assets/icons/svg/project-inaccessible.svg').default
};

export const dtDownloadIcon = {
    name: 'dt_download',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_download.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_download.svg').default
};

export const dtSensorBoostModeIcon = {
    name: 'dt_sensor_boost_mode',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/ic_sensor_boost_mode.svg'),
    src: require('raw-loader!../../assets/icons/svg/ic_sensor_boost_mode.svg').default
};

export const dtDevelopersIcon = {
    name: 'dt_header_developers',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../sass/dt-design-system/src/scss/assets/dt-icon-developer.svg'),
    src: require('raw-loader!../../sass/dt-design-system/src/scss/assets/dt-icon-developer.svg').default
};

export const dtStudioIcon = {
    name: 'dt_header_studio',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../sass/dt-design-system/src/scss/assets/dt-icon-studio.svg'),
    src: require('raw-loader!../../sass/dt-design-system/src/scss/assets/dt-icon-studio.svg').default
};

export const dtSupportIcon = {
    name: 'dt_header_support',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../sass/dt-design-system/src/scss/assets/dt-icon-support.svg'),
    src: require('raw-loader!../../sass/dt-design-system/src/scss/assets/dt-icon-support.svg').default
};

export const dtStoreIcon = {
    name: 'dt_header_store',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/dt-icon-store.svg'),
    src: require('raw-loader!../../assets/icons/svg/dt-icon-store.svg').default
};

export const dtUserIcon = {
    name: 'dt_header_user',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../sass/dt-design-system/src/scss/assets/dt-icon-user.svg'),
    src: require('raw-loader!../../sass/dt-design-system/src/scss/assets/dt-icon-user.svg').default
};

export const dtGlobeIcon = {
    name: 'dt_globe',
    url: require('file-loader?name=assets/icons/[name].[ext]!../../assets/icons/svg/globe.svg'),
    src: require('raw-loader!../../assets/icons/svg/globe.svg').default
};
