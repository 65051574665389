export const RANGE_LABEL_5_MIN = '5 min';
export const RANGE_LABEL_HOUR = 'Hour';
export const RANGE_LABEL_DAY = 'Day';
export const RANGE_LABEL_WEEK = 'Week';
export const RANGE_LABEL_MONTH = 'Month';
export const RANGE_LABEL_3_MONTHS = '3 Months';
export const RANGE_LABEL_6_MONTHS = '6 Months';
export const RANGE_LABEL_12_MONTHS = '12 Months';
export const RANGE_LABEL_3_YEARS = '3 Years';

export const SENSOR_GRAPH_RANGES = [
    RANGE_LABEL_5_MIN,
    RANGE_LABEL_HOUR,
    RANGE_LABEL_DAY,
    RANGE_LABEL_WEEK,
    RANGE_LABEL_MONTH,
    RANGE_LABEL_3_MONTHS,
    RANGE_LABEL_6_MONTHS,
    RANGE_LABEL_12_MONTHS,
    RANGE_LABEL_3_YEARS,
];

export const EXTENDED_SENSOR_GRAPH_RANGES = [
    RANGE_LABEL_3_MONTHS,
    RANGE_LABEL_6_MONTHS,
    RANGE_LABEL_12_MONTHS,
    RANGE_LABEL_3_YEARS,
];

export const SECOND = 1000; // in milliseconds
export const MINUTE = 60 * SECOND; // in milliseconds
export const HOUR = 60 * MINUTE; // in milliseconds
export const DAY = 24 * HOUR; // in milliseconds
export const MONTH = 31 * DAY; // in milliseconds

export const RANGE_DURATIONS = {
    [RANGE_LABEL_5_MIN]: 5 * MINUTE,
    [RANGE_LABEL_HOUR]: HOUR,
    [RANGE_LABEL_DAY]: DAY,
    [RANGE_LABEL_WEEK]: 7 * DAY,
    [RANGE_LABEL_MONTH]: MONTH,
    [RANGE_LABEL_3_MONTHS]: 3 * MONTH,
    [RANGE_LABEL_6_MONTHS]: 6 * MONTH,
    [RANGE_LABEL_12_MONTHS]: 12 * MONTH,
    [RANGE_LABEL_3_YEARS]: 3 * 12 * MONTH,
};

export const TEMPERATURE_GRAPH_FILTERS = {
    NONE: {
        displayName: 'No Filter',
        description: 'Display normal temperature data.',
        value: 'NONE'
    },
    GLYCOL: {
        displayName: 'Virtual Glycol Buffer (Beta)',
        description: 'Add a virtual filter to approximate the temperature of items placed in fridges and freezers.',
        value: 'GLYCOL'
    }
}

export const SENSOR_GRAPH_RANGE_STATE_KEY = 'sensor:sensorGraphRange';

export const ALERT_EVENT_TYPES = {
    TRIGGERED: "TRIGGERED",
    ACKNOWLEDGED: "ACKNOWLEDGED",
    UNACKNOWLEDGED: "UNACKNOWLEDGED",
    ESCALATED: "ESCALATED",
    COMMENT: "COMMENT",
    ALERT_DELIVERY_SUCCEEDED: "ALERT_DELIVERY_SUCCEEDED",
    ALERT_DELIVERY_FAILED: "ALERT_DELIVERY_FAILED",
    CORRECTIVE_ACTION: "CORRECTIVE_ACTION",
    RESOLVED: "RESOLVED",
    ARCHIVED: "ARCHIVED"
}

export const ALERT_USER_ACTIONS = {
    ACKNOWLEDGE: "ACKNOWLEDGE",
    COMMENT: "COMMENT",
    CORRECTIVE_ACTION: "CORRECTIVE_ACTION",
}