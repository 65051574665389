import moment from 'moment';
import { getHoursMinutesFormat} from 'services/utils';

export default {
    chart: {
        type: 'line',
        marginLeft: 68
    },
    yAxis: [
        {
            allowDecimals: true,
            minTickInterval: 0.5,
            tickPixelInterval: 32,
            labels: {
                get format() {
                    return `{value} ppm`;
                }
            },
        },
        {
            visible: false
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
    },
    boost: {
        enabled: false
    },
    tooltip: {
        enabled: true,
        followPointer: true,
        outside: true,
        shadow: false,
        animation: false,
        hideDelay: 100,
        borderRadius: 12,
        padding: 10,
        shared: true,
        useHTML: true,
        formatter() {
            const momentFormat = `${getHoursMinutesFormat()} ddd, MMM D`;
            const timestamp = moment(this.x).format(momentFormat);
            let tooltipHtml = `
                <p style="font-weight:bold; color: white; text-align: center; width: 100%; margin-top: 2px; margin-bottom: 6px;">
                    ${timestamp}
                </p>`

            // Get min/max/avg temperature values to determine which tooltip variant to show
            let min = null
            let max = null
            let avgOrRaw = null // avg if min/max is present, raw if not

            this.points.forEach(function(entry) {
                if (entry.series.name === 'CO2') {
                    avgOrRaw = entry.y.toFixed(0);
                }
                if (entry.series.name === 'Min/Max') {
                    min = entry.point.low.toFixed(0);
                    max = entry.point.high.toFixed(0);
                }
            })
            tooltipHtml += "<table>"
            if (min !== null && max !== null) {
                tooltipHtml += `
                <tr>
                    <td style="color: white; padding-right: 36px;">
                        Max
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${max} ppm
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 36px;">
                        Avg
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${avgOrRaw} ppm
                    </td>
                </tr>
                <tr>
                    <td style="color: white; padding-right: 36px;">
                        Min
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${min} ppm
                    </td>
                </tr>`
            } else {
                tooltipHtml += `
                <tr>
                    <td style="color: white; padding-right: 12px;">
                        CO2
                    </td>
                    <td style="font-weight:bold; text-align: right; color: white">
                        ${avgOrRaw} ppm
                    </td>
                </tr>`
            }
            
            tooltipHtml += "</table>";
            return tooltipHtml;
        }
    },
    plotOptions: {
        line: {
            connectNulls: true,
            getExtremesFromAll: false,
            marker: {
                radius: 3
            }
        }
    }
};
