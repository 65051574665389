
export default {
    chart: {
        animation: false,
        zoomType: 'x',
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: 0,
        marginTop: 10,
        marginBottom: 22,
        marginRight: 0,
        marginLeft: 44,
        height: 176,
    },
    scrollbar: {
        enabled: false,
    },
    yAxis: [
        {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
                format: '{value}%'
            }
        },
        {
            visible: false
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
    },
    tooltip: {
        enabled: true,
        shared: true,
        outside: false
    },
    plotOptions: {
        line: {
            getExtremesFromAll: false,
            marker: {
                enabledThreshold: 4
            }
        },
        series: {
            turboThreshold: 0
        }
    }
};
