import controller from './active-alerts.controller';

const template = require('./active-alerts.html');

export default {
    selector: 'activeAlerts',
    options: {
        bindings: {
            alerts: '=',
        },
        controller,
        template
    }
};
