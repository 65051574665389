import moment from 'moment';
import { getHistoryChartType, getHistoryEventType } from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { AbstractChartController } from '../chart-abstract-base';

/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartHumidityController extends AbstractChartController {
    constructor(SensorEventsLoader, ToastService, $scope,EventEmitter) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    // Overrides AbstractChartController
    get dataSeries() {
        return {
            addPoint: (temperature, humidity, date) => {
                this.chart.series[0].addPoint([date, temperature], false);
                this.chart.series[2].addPoint([date, humidity], false);
            },
            setData: (data) => {
                this.chart.series[0].setData(data.map(item => [item[0], item[1]]));          // Temperature
                this.chart.series[2].setData(data.map(item => [item[0], item[2]]));          // Humidity
                this.chart.series[3].setData(data.map(item => [item[0], item[3], item[4]])); // Temperature min/max
                this.chart.series[4].setData(data.map(item => [item[0], item[5], item[6]])); // Humidity min/max
            }
        }
    }

    // Overrides AbstractChartController
    getEventTypes() {
        return [this.eventType];
    }

    // Overrides AbstractChartController
    getAggregationFields() {
        return [
            {"fieldName": "humidity.temperature",      "type": "AVERAGE"},
            {"fieldName": "humidity.temperature",      "type": "MIN"},
            {"fieldName": "humidity.temperature",      "type": "MAX"},
            {"fieldName": "humidity.relativeHumidity", "type": "AVERAGE"},
            {"fieldName": "humidity.relativeHumidity", "type": "MIN"},
            {"fieldName": "humidity.relativeHumidity", "type": "MAX"},
            // For offline detection
            {"fieldName": "humidity.relativeHumidity", "type": "COUNT"},
        ];
    }

    // Overrides AbstractChartController
    getConfigPreset() {
        return ConfigPresets.Humidity;
    }

    // Overrides AbstractChartController
    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.humiditySeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax
        );
    }

    // Overrides AbstractChartController
    convertEvents(events) {
        const eventTimestamps = events.map(e => moment(e.data.humidity.updateTime).valueOf())

        this.plotBands = DataConverter.createOfflineBands(
            eventTimestamps,
            this.chartMin,
            this.chartMax
        );

        this.chartData = DataConverter.syncOfflineTemperature(
            DataConverter.humidity(events),
            this.plotBands
        );

        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    // Overrides AbstractChartController
    convertAggregated(aggregatedData) {
        this.chartData = aggregatedData.results[0].values.map(aggWindow => {
            return [
                moment(aggWindow.timeWindow).valueOf(),
                DataConverter.celsiusToUserPreference(aggWindow.values["humidity.temperature_AVERAGE"]),
                aggWindow.values["humidity.relativeHumidity_AVERAGE"],
                DataConverter.celsiusToUserPreference(aggWindow.values["humidity.temperature_MIN"]),
                DataConverter.celsiusToUserPreference(aggWindow.values["humidity.temperature_MAX"]),
                aggWindow.values["humidity.relativeHumidity_MIN"],
                aggWindow.values["humidity.relativeHumidity_MAX"],
            ]
        })

        this.plotBands = DataConverter.createAggregatedOfflineBands(
            aggregatedData.results[0].values,
            "humidity.relativeHumidity_COUNT",
        );
        
        return {
            data: this.chartData,
            plotBands: this.plotBands,
        }
    }

    // Overrides AbstractChartController
    onStateEventReceived({ temperature, relativeHumidity, updateTime }) {
        const timestamp = +new Date(updateTime);
        const temp = DataConverter.celsiusToUserPreference(temperature);

        this.adjustMinMaxValues(temp);
        this.dataSeries.addPoint(temp, relativeHumidity, timestamp);
    }

    adjustMinMaxValues(value) {
        if (value < this.spacerMin) {
            this.spacerMin = value;
        } else if (value > this.spacerMax) {
            this.spacerMax = value;
        }
    }

    $onInit() {
        if (this.thing.type === 'co2') {
            this.chartType = 'humidity';
            this.eventType = 'humidity';
        } else {
            this.chartType = getHistoryChartType(this.thing);
            this.eventType = getHistoryEventType(this.thing);
        }

        super.$onInit();
    }
}
