import { cloneEntity } from 'services/utils';
import { States } from '../../app.router';
import listComponent from './alerts.component';
import detailComponent from './rules/rules-detail/rules-detail.component'

/**
 * @param $stateProvider
 * @constructor
 * @ngInject
 */
export default function Routes($stateProvider) {
    $stateProvider
        .state(States.ALERTS, {
            url: '/alerts',
            views: {
                'content@main': {
                    component: listComponent.selector
                }
            },
            resolve: {
                activeState: /* @ngInject */ () => {
                    return States.ALERTS;
                }
            }
        })
        .state(States.ALERTS_LOG, {
            url: '/log',
            views: {
                'content@main': {
                    component: listComponent.selector
                }
            },
            resolve: {
                activeState: /* @ngInject */ () => {
                    return States.ALERTS_LOG;
                }
            }
        })
        .state(States.ALERTS_RULES, {
            url: '/rules',
            views: {
                'content@main': {
                    component: listComponent.selector
                }
            },
            resolve: {
                activeState: /* @ngInject */ () => {
                    return States.ALERTS_RULES;
                } 
            }
        })
        .state(States.ALERTS_RULES_DETAIL, {
            url: '/{ruleId}',
            params: {
                rule: null
            },
            resolve: {
                activeState: /* @ngInject */ () => {
                    return States.ALERTS_RULES_DETAIL;
                },
                isNew: /* @ngInject */ $stateParams => $stateParams.ruleId === 'new',
                
                rule: /* @ngInject */ (isNew, $stateParams, $q, AlertsAndRulesService) => {
                    if (isNew) {
                        return {
                            displayName: '',
                            enabled: true,
                            trigger: null,
                            escalationLevels: [
                                {
                                    displayName: 'Escalation Level 1',
                                    actions: [],
                                    escalationAfter: ''
                                }
                            ],
                        };
                    }
                    if ($stateParams.rule?.id === $stateParams.ruleId) {
                        return cloneEntity($stateParams.rule);
                    }
                    return $q((resolve) => {
                        AlertsAndRulesService.getRule($stateParams.ruleId).then(
                            (rule) => resolve(rule)
                        ).catch(() => resolve());
                    });
                }
            },
            onEnter: /* @ngInject */ (rule, $state, $stateParams) => { // eslint-disable-line consistent-return
                if (!rule) {
                    return $state.target(States.ALERTS_RULES_DETAIL, {
                        projectId: $stateParams.projectId
                    });
                }
            },
            component: detailComponent.selector
        });
}
