import controller from './rules-detail.controller';

const template = require('./rules-detail.html');

export default {
    selector: 'studioAlertsRuleDetailPage',
    options: {
        bindings: {
            rule: '<',
            isNew: '<',
            onUpdate: '&',
            onDelete: '&',
            onCreate: '&'
        },
        controller,
        template
    }
};
