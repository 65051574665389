import moment from 'moment';
import _merge from 'lodash/merge';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';

import ConfigPresets from 'services/charting/presets';
import { DataConverter, ConfigHelper } from 'services/charting';
import { HIGH_POWER_BOOST_MODE, PLOT_BAND_BOOST_PREFIX } from 'services/charting/data-converter';
import { forceRedrawOnIOS, noop, getHoursMinutesSecondsFormat } from 'services/utils';
import { APP_CONTENT_RESIZE_EVENT } from 'services/StudioEvents';
import { dataSeries } from 'services/charting/config-helper';

const SECOND = 1000; // in milliseconds
const MINUTE = 60 * SECOND; // in milliseconds
const HOUR = 60 * MINUTE; // in milliseconds
const DAY = 24 * HOUR; // in milliseconds

const RANGE_LABEL_5_MIN = '5 min';
const RANGE_LABEL_HOUR = 'Hour';
const RANGE_LABEL_DAY = 'Day';
const RANGE_LABEL_WEEK = 'Week';
const RANGE_LABEL_ALL = 'Month';

const RANGE_DURATIONS = {
    [RANGE_LABEL_5_MIN]: 5 * MINUTE,
    [RANGE_LABEL_HOUR]: HOUR,
    [RANGE_LABEL_DAY]: DAY,
    [RANGE_LABEL_WEEK]: 7 * DAY
};

const MESSAGE_LOADING = 'Loading data from server...';

const CHART_X_AXIS_INDEX = 0;
const CHART_DATA_SERIES_INDEX = 0;
const CHART_SPACER_SERIES_INDEX = 0;

const DEFAULT_CCON_TOOLTIP_PROPS = {
    signalStrength: null,
    boostMode: false
};

const ASYNC_EVENTS_CHUNK_COUNT = 4;

const PAN_TRACKING_DEBOUNCE_DELAY = 250; // in milliseconds

export const CONNECTIVITY_GRAPH_RANGE_STATE_KEY = 'sensor:connectivityGraphRange';

/* @ngInject */
export default class ThingChartController {
    constructor(
        SensorService,
        $scope,
        $element,
        ToastService,
        $q,
        StateService,
        CloudConnectorHelper
    ) {
        this.SensorService = SensorService;
        this.$scope = $scope;
        this.elementNode = $element[0];
        this.ToastService = ToastService;
        this.$q = $q;
        this.StateService = StateService;
        this.CloudConnectorHelper = CloudConnectorHelper;

        this.addEventChunk = this.addEventChunk.bind(this);
        this.handleEvents = this.handleEvents.bind(this);
        this.processEagerEvents = this.processEagerEvents.bind(this);
        this.processLazyEvents = this.processLazyEvents.bind(this);
        this.addHeartbeatEventPoint = this.addHeartbeatEventPoint.bind(this);
        this.updateSpacerSeries = this.updateSpacerSeries.bind(this);
        this.onChartSelection = this.onChartSelection.bind(this);
        this.onAfterSetExtremes = this.onAfterSetExtremes.bind(this);
        this.onChartLoaded = this.onChartLoaded.bind(this);
        this.loadMissingData = _debounce(this.loadMissingData.bind(this), PAN_TRACKING_DEBOUNCE_DELAY);
    }

    get xAxis() {
        return this.chart.xAxis[CHART_X_AXIS_INDEX];
    }

    get dataSeries() {
        return this.chart.series[CHART_DATA_SERIES_INDEX];
    }

    get spacerSeries() {
        return this.chart.series[CHART_SPACER_SERIES_INDEX];
    }

    convertChartData(data) {
        return DataConverter.connectivity(data, this.thing.productNumber);
    }

    onChartLoaded({ chart }) {
        this.chart = chart;
        this.initResizeListener();
        forceRedrawOnIOS();
        this.updateTooltip();
        if (this.storedRange) {
            this.setRange(this.storedRange);
        }
    }

    onChartSelection() {
        Object.keys(this.ranges).forEach((key) => {
            this.ranges[key] = false;
        });
        this.$scope.$applyAsync();
    }

    onAfterSetExtremes({
        min, max, dataMax
    }) {
        this.stopSpacerTicker();
        if (ConfigHelper.isCloseToAxisMax(min, max, dataMax)) {
            const interval = ConfigHelper.getRefreshInterval(min, max);
            if (interval !== null) {
                this.initSpacerTicker(interval);
            }
        }
        this.loadMissingData();
    }

    prepareChartConfig(data, plotBands) {
        const $ctrl = this;
        
        this.chartConfig = _merge(
            {
                chart: {
                    events: {
                        selection: this.onChartSelection
                    }
                }
            },
            ConfigPresets.Base,
            this.alertView ? ConfigPresets.AlertViewConnectivity : ConfigPresets.Connectivity,
            ConfigHelper.connectivitySeries(data, this.spacerStart, this.spacerEnd, this.spacerMin, this.spacerMax),
            ConfigHelper.plotBands(plotBands),
            ConfigHelper.extremes(this.chartMin, this.chartMax),
            {
                xAxis: {
                    min: this.graphStart,
                    max: this.graphEnd,
                    events: {
                        afterSetExtremes: this.onAfterSetExtremes
                    }
                }
            },
            this.alertView ? {} : {
                tooltip: {
                    formatter() {
                        const { points } = this;
                        $ctrl.prepareTooltipData(points);
                        $ctrl.$scope.$applyAsync();
                        return false;
                    }
                }
            }
        );

        this.dataLoaded = true;
    }

    addConnectivityEventPoint(eventData) {
        const boostMode = eventData.transmissionMode === HIGH_POWER_BOOST_MODE;
        const timestamp = moment(eventData.updateTime).startOf('second').valueOf();

        if (boostMode) {
            let boostStart = null;
            this.chart.series.slice(1).forEach((series) => {
                let index = series.data.length - 1;
                while (index >= 0) {
                    if (series.data[index].boostMode) {
                        if (boostStart === null) {
                            boostStart = series.data[index].x;
                        } else {
                            boostStart = series.data[index].x < boostStart ? series.data[index].x : boostStart;
                        }
                        index--;
                    } else {
                        break;
                    }
                }
            });

            if (boostStart) {
                const id = DataConverter.plotBandId(boostStart, PLOT_BAND_BOOST_PREFIX);
                this.xAxis.removePlotBand(id);
            } else {
                boostStart = timestamp;
            }

            this.xAxis.addPlotBand(
                DataConverter.plotBandForBoostMode(boostStart, timestamp)
            );
        }

        const knownCloudConnectors = [];
        const newCloudConnectors = [];

        const seriesMap = this.chart.series.reduce((acc, series) => ({
            ...acc,
            [series.name]: series
        }), {});

        eventData.cloudConnectors.forEach((ccon) => {
            if (seriesMap[ccon.id]) {
                knownCloudConnectors.push(ccon);
            } else {
                newCloudConnectors.push(ccon);
            }
        });

        let needsRedraw = false;

        if (knownCloudConnectors.length) {
            knownCloudConnectors.forEach(({ id, signalStrength }) => {
                const lastPoint = seriesMap[id].data[seriesMap[id].data.length - 1];
                if (timestamp - lastPoint.x > DataConverter.heartbeatMaxGap(this.thing.productNumber)) {
                    seriesMap[id].addPoint({
                        x: timestamp - 1,
                        y: null,
                        boostMode: false
                    }, false);
                }

                seriesMap[id].addPoint({
                    x: timestamp,
                    y: signalStrength,
                    boostMode
                }, false);

                needsRedraw = true;
            });
        }

        if (newCloudConnectors.length) {
            newCloudConnectors.forEach(({ id, signalStrength }) => {
                this.chart.addSeries({
                    name: id,
                    data: [{
                        x: timestamp,
                        y: signalStrength,
                        boostMode
                    }]
                }, false);

                needsRedraw = true;
            });

            this.updateTooltip();
        }

        if (needsRedraw) {
            this.chart.redraw();
        }
    }

    syncOfflinePlotBand(lastSeen) {
        const from = moment(lastSeen || this.chartMin).valueOf();
        const id = DataConverter.plotBandId(from);

        this.xAxis.removePlotBand(id);
        this.xAxis.addPlotBand(
            DataConverter.plotBand(from, this.chartMax)
        );
    }

    addHeartbeatEventPoint() {
        if (this.thing.offline) {
            this.syncOfflinePlotBand(this.thing.lastSeen);
        }
    }

    $onChanges(changes) {
        if (
            changes.eventsObservable
            && changes.eventsObservable.currentValue
            && changes.eventsObservable.currentValue !== changes.eventsObservable.previousValue
        ) {
            const events$ = changes.eventsObservable.currentValue;
            events$.subscribe((event) => {
                if (this.chart && event.eventType === 'networkStatus') {
                    const { min, max, dataMax } = this.xAxis.getExtremes();
                    if (ConfigHelper.isCloseToAxisMax(min, max, dataMax)) {
                        this.updateSpacerSeries();
                    }
                    this.addConnectivityEventPoint(event.data.networkStatus);
                    if (ConfigHelper.isCloseToAxisMax(min, max, dataMax)) {
                        this.prepareTooltipData();
                        this.$scope.$applyAsync();
                    }
                }
            });
        }
    }

    resetEventContainer() {
        this.events = [];
    }

    addEventChunkContainer(chunkId = 0) {
        this.events[chunkId] = [];
    }

    addEventChunk(event, chunkId) {
        this.events[chunkId].push(event);
    }

    getEventsFromContainer(chunkId) {
        return this.events[chunkId].reverse();
    }

    handleEvents(params, { data, nextPageToken }, chunkId) {
        data.forEach((event) => {
            this.addEventChunk(event, chunkId);
        });

        if (nextPageToken) {
            return this.loadEvents({
                ...params,
                pageToken: nextPageToken,
                pageSize: 10000
            }, chunkId);
        }

        return this.getEventsFromContainer(chunkId);
    }

    loadEvents(params, asyncChunkId = 0) {
        return this.SensorService
            .events(this.thing.name, params)
            .then(data => this.handleEvents(params, data, asyncChunkId));
    }

    loadEventsAsync(params) {
        let { startTime, endTime } = params;

        startTime = moment(startTime).valueOf();
        endTime = moment(endTime).valueOf();

        const diff = endTime - startTime;

        if (diff > DAY) {
            const chunkStep = Math.floor(diff / ASYNC_EVENTS_CHUNK_COUNT);
            const promises = [];

            for (let i = 0; i < ASYNC_EVENTS_CHUNK_COUNT; i++) {
                const newStartTime = i === 0
                    ? startTime
                    : (startTime + (chunkStep * i) + 1);
                const newEndTime = (i === ASYNC_EVENTS_CHUNK_COUNT - 1)
                    ? endTime
                    : ((newStartTime + chunkStep) - (i === 0 ? 0 : 1));

                this.addEventChunkContainer(i);

                promises.push(this.loadEvents({
                    ...params,
                    startTime: moment(newStartTime).format(),
                    endTime: moment(newEndTime).format()
                }, i));
            }

            return this.$q.all(promises)
                .then(chunks => [].concat(...chunks));
        }

        this.addEventChunkContainer();

        return this.loadEvents(params);
    }

    setRange(label) {
        if (this.dataLoading) return;

        Object.keys(this.ranges).forEach((key) => {
            this.ranges[key] = false;
        });
        this.ranges[label] = true;

        this.StateService.setItem(CONNECTIVITY_GRAPH_RANGE_STATE_KEY, label);

        const chartMin = this.spacerStart;
        const chartMax = this.spacerEnd;

        const { max } = this.xAxis.getExtremes();
        if (label === RANGE_LABEL_ALL) {
            this.xAxis.setExtremes(chartMin, chartMax);
        } else {
            const newMin = max - RANGE_DURATIONS[label];
            if (newMin < chartMin) {
                this.xAxis.setExtremes(chartMin, chartMin + RANGE_DURATIONS[label]);
            } else {
                this.xAxis.setExtremes(newMin, max);
            }
        }
    }

    convertEvents(networkStatus) {
        const networkEventsTimestamps = DataConverter.getTimestampsFromNetworkStatusEvents(networkStatus)
        this.plotBands = [
            ...DataConverter.createOfflineBands(networkEventsTimestamps, this.chartMin, this.chartMax, this.thing.productNumber),
            ...DataConverter.boostMode(networkStatus)
        ];
        this.chartData = this.convertChartData(networkStatus);
        return {
            plotBands: this.plotBands,
            data: this.chartData
        };
    }

    processEagerEvents(events) {
        const { plotBands, data } = this.convertEvents(events);
        this.prepareChartConfig(data, plotBands);
    }

    processLazyEvents(events) {
        this.plotBands
            .map(band => band.id)
            .forEach((bandId) => {
                this.xAxis.removePlotBand(bandId);
            });

        const { plotBands, data } = this.convertEvents(events);

        plotBands.forEach((band) => {
            this.xAxis.addPlotBand(band);
        });

        this.chart.series.slice(1).forEach((series) => {
            series.remove(false);
        });
        Object.keys(data).forEach((id, index) => {
            this.chart.addSeries(dataSeries(id, data[id], false, index), false);
        });

        this.updateTooltip();

        this.chart.redraw();
    }

    loadDataForDates(start, end) {
        this.chartMin = start.valueOf();
        this.chartMax = end.valueOf();

        this.graphStart = moment().subtract(1, 'day').valueOf();
        this.graphEnd = moment().valueOf();

        this.resetEventContainer();

        this.dataLoading = true;

        return this.loadEventsAsync({
            startTime: start.format(),
            endTime: end.format(),
            eventTypes: ['networkStatus']
        })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('device_events_wasnt_loaded', {
                    serverResponse
                });
                return [];
            })
            .finally(() => {
                this.dataLoading = false;
            });
    }

    loadDataForDay() {
        const start = moment().subtract(1, 'day').startOf('day');
        const end = moment();

        this.dataLoadedSince = start;

        this.loadDataForDates(start, end).then(this.processEagerEvents);
    }

    loadDataSince(start) {
        const end = moment();

        this.chart.showLoading(MESSAGE_LOADING);
        this.loadingSince = true;
        this.stopSpacerTicker();

        this.loadDataForDates(start, end).then((events) => {
            this.processLazyEvents(events);
            this.dataLoadedSince = start;
            this.loadingSince = false;
            this.chart.hideLoading();
            if (this.needsReloadSince) {
                this.needsReloadSince = false;
                this.loadMissingData();
            }
        });
    }

    loadMissingData() {
        if (this.loadingSince) {
            this.needsReloadSince = true;
            return;
        }

        const { min } = this.xAxis.getExtremes();

        let start = moment(min).startOf('day');
        if (start.diff(this.spacerStart) < 0) {
            start = moment(this.spacerStart);
        }

        if (start.diff(this.dataLoadedSince) < 0) {
            this.loadDataSince(start);
        }
    }

    $onInit() {
        this.chartData = null;
        this.dataLoaded = false;

        this.ranges = {
            [RANGE_LABEL_HOUR]: false,
            [RANGE_LABEL_DAY]: true,
            [RANGE_LABEL_WEEK]: false,
            [RANGE_LABEL_ALL]: false
        };
        this.storedRange = this.StateService.getItem(CONNECTIVITY_GRAPH_RANGE_STATE_KEY);

        this.weekDataLoaded = false;
        this.monthDataLoaded = false;

        this.spacerStart = moment().subtract(1, 'month').valueOf();
        this.spacerEnd = moment().valueOf();

        this.spacerMin = 0;
        this.spacerMax = 0;

        this.cloudConnectorMap = {};

        this.dataLoadedSince = null;

        this.loadingSince = false;
        this.needsReloadSince = false;

        this.loadDataForDay();

        this.removeResizeListener = noop;

        this.$scope.$watch('$ctrl.parentDateRange', (newRange) => {
            if (newRange) {
                this.setRange(newRange);
            }
        });
    }

    updateTooltip() {
        this.initTooltipData();
        this.prepareTooltipData();
        this.updateCloudConnectorNames();
    }

    initTooltipData() {
        this.tooltipTitle = moment(this.thing.reported.networkStatus.updateTime).format(`dddd, MMM D, ${getHoursMinutesSecondsFormat()}`);
        this.tooltipData = this.chart.series.slice(1).map(series => ({
            index: series.colorIndex,
            id: series.name,
            ...DEFAULT_CCON_TOOLTIP_PROPS
        }));
    }

    prepareTooltipData(points = null) {
        let dataMap;
        if (points) {
            dataMap = points.reduce((acc, point) => ({
                ...acc,
                [point.series.name]: {
                    signalStrength: point.y,
                    boostMode: point.point.boostMode
                }
            }), {});
            this.tooltipTitle = moment(points[0].x).format(`dddd, MMM D, ${getHoursMinutesSecondsFormat()}`);
        } else {
            const networkStatus = _get(this.thing, 'reported.networkStatus', {});
            const cloudConnectors = _get(networkStatus, 'cloudConnectors', []);
            const boostMode = _get(networkStatus, 'transmissionMode') === HIGH_POWER_BOOST_MODE;
            dataMap = cloudConnectors.reduce((acc, ccon) => ({
                ...acc,
                [ccon.id]: {
                    signalStrength: this.thing.offline ? null : ccon.signalStrength,
                    boostMode
                }
            }), {});
            const lastUpdateTime = _get(this.thing, 'reported.networkStatus.updateTime', null)
            if (lastUpdateTime !== null && !this.thing.offline) {
                this.tooltipTitle = moment(lastUpdateTime).format(`dddd, MMM D,${getHoursMinutesSecondsFormat()}`);
            } else {
                this.tooltipTitle = 'Offline'
            }
        }
        this.tooltipData = this.tooltipData.map(item => ({
            ...item,
            ...(dataMap[item.id] ? dataMap[item.id] : DEFAULT_CCON_TOOLTIP_PROPS)
        }));
    }

    updateSpacerSeries() {
        this.chartMax = moment().valueOf();
        this.spacerEnd = this.chartMax;
        this.spacerSeries.setData(
            ConfigHelper.spacerSeriesData(this.spacerStart, this.spacerEnd, this.spacerMin, this.spacerMax)
        );
        this.addHeartbeatEventPoint();
    }

    initSpacerTicker(delay) {
        this.spacerTicker = setTimeout(this.updateSpacerSeries, delay);
    }

    stopSpacerTicker() {
        clearTimeout(this.spacerTicker);
    }

    initResizeListener() {
        this.removeResizeListener = this.$scope.$root.$on(APP_CONTENT_RESIZE_EVENT, () => {
            this.elementNode.style.width = '250px'; // any value that makes container narrow enough
            this.chart.reflow();
            this.elementNode.style.width = ''; // spread over available space again

            setTimeout(() => {
                this.chart.reflow();
            }, 0);
        });
    }

    updateCloudConnectorNames() {
        this.tooltipData.forEach((item) => {
            if (!this.cloudConnectorMap[item.id]) {
                if (item.id !== 'emulated-ccon') {
                    this.CloudConnectorHelper
                        .loadInfo(item.id)
                        .then((cconInfo) => {
                            this.cloudConnectorMap[item.id] = cconInfo;
                        })
                }
            }
        });
    }

    $onDestroy() {
        this.removeResizeListener();
        this.stopSpacerTicker();
    }
}
