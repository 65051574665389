import controller from './alert-log.controller';

const template = require('./alert-log.html');

export default {
    selector: 'alertLog',
    options: {
        // bindings: {
        //     alerts: '=',
        // },
        // bindings: {
        //     rule: '<',
        //     isNew: '<',
        //     onUpdate: '&',
        //     onDelete: '&',
        //     onCreate: '&'
        // },
        controller,
        template
    }
};
