
/**
 * @ngInject
 */
export default class AlertGuideController {
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
    }

    $onInit() {
      
    }
}
