import controller from './duration.controller';

const template = require('./duration.html');

export default {
    selector: 'dtAlertsDuration',
    options: {
        require: {
            ngModelCtrl: "ngModel",
            parentForm: '?^form'
        },
        bindings: {
            min: '@',
            max: '@',
            hideSeconds: '<?' || false,
        },
        controller,
        template
    }
};
