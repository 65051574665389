import moment from 'moment';
import _isElement from 'lodash/isElement';
import enLang from 'services/translation/en.lang';
import imgEthernet from '../../../assets/images/img_ethernet.svg';
import { getHoursMinutesSecondsFormat} from 'services/utils';

export const OFFLINE_PLOT_BAND_CLASS_NAME = 'highcharts-plot-band--missing-data';
export const BOOST_PLOT_BAND_CLASS_NAME = 'highcharts-plot-band--boost';
export const ETHERNET_PLOT_BAND_CLASS_NAME = 'highcharts-plot-band--ethernet';
export const INCOMPLETE_DATA_PLOT_BAND_CLASS_NAME = 'highcharts-plot-band--incomplete-data';

export const forNetwork = {
    className: OFFLINE_PLOT_BAND_CLASS_NAME,
    zIndex: 6,
    events: {
        mouseover(tooltipTarget) {
            this.axis.chart.tooltip.options.enabled = false
            const momentFormat = `MMM Do, ${getHoursMinutesSecondsFormat()}`;
            const fromStamp = moment(this.options.from).format(momentFormat);
            const toStamp = moment(this.options.to).format(momentFormat);

            if (_isElement(tooltipTarget)) {
                tooltipTarget.innerText = `No data: ${fromStamp} – ${toStamp}`;
                return;
            }

            const chart = this.axis.chart;
            if (!chart.lblNetwork) {
                chart.lblNetwork = chart.renderer.text('', 60, 40, true)
                    .add();
            }
            chart.lblNetwork
                .show()
                .removeClass('highcharts-boost-mode-label')
                .removeClass('highcharts-ethernet-label')
                .addClass('highcharts-missing-data-label')
                .attr({
                    text: `No data: ${fromStamp} – ${toStamp}`,
                });
        },
        mouseout(tooltipTarget) {
            this.axis.chart.tooltip.options.enabled = true
            if (_isElement(tooltipTarget)) {
                tooltipTarget.innerText = '';
                return;
            }
            const chart = this.axis.chart;
            if (chart.lblNetwork) {
                chart.lblNetwork
                    .removeClass('highcharts-missing-data-label')
                    .hide();
            }
        },
    },
};

export const forBoostMode = {
    className: BOOST_PLOT_BAND_CLASS_NAME,
    zIndex: 4,
    events: {
        mouseover() {
            const chart = this.axis.chart;
            if (!chart.lblNetwork) {
                chart.lblNetwork = chart.renderer.text('', 40, 216, true)
                    .add();
            }
            chart.lblNetwork
                .show()
                .removeClass('highcharts-missing-data-label')
                .addClass('highcharts-boost-mode-label')
                .attr({
                    text: `${enLang.sensor_mode_HIGH_POWER_BOOST_MODE}`,
                });
        },
        mouseout() {
            const chart = this.axis.chart;
            if (chart.lblNetwork) {
                chart.lblNetwork
                    .removeClass('highcharts-boost-mode-label')
                    .hide();
            }
        },
    },
};

export const forEthernet = {
    className: ETHERNET_PLOT_BAND_CLASS_NAME,
    zIndex: 4,
    events: {
        mouseover() {
            const momentFormat = `MMM D, ${getHoursMinutesSecondsFormat()}`;
            const fromStamp = moment(this.options.from).format(momentFormat);
            const toStamp = moment(this.options.to).format(momentFormat);
            const chart = this.axis.chart;
            if (!chart.lblNetwork) {
                chart.lblNetwork = chart.renderer.text('', 40, 40, true)
                    .add();
            }
            chart.lblNetwork
                .show()
                .removeClass('highcharts-missing-data-label')
                .addClass('highcharts-ethernet-label')
                .attr({
                    text: `<img src="${imgEthernet}" /> Ethernet connected ${fromStamp} – ${toStamp}`,
                });
        },
        mouseout() {
            const chart = this.axis.chart;
            if (chart.lblNetwork) {
                chart.lblNetwork
                    .removeClass('highcharts-ethernet-label')
                    .hide();
            }
        },
    },
};

export const forIncompleteData = {
    className: INCOMPLETE_DATA_PLOT_BAND_CLASS_NAME,
    zIndex: 4,
    events: {
        mouseover() {
            const remarkHoverInfo = document.getElementById('remarks-warning')
            remarkHoverInfo.classList.add('visible')
        },
        mouseout() {
            const remarkHoverInfo = document.getElementById('remarks-warning')
            setTimeout(() => {
                // Show the warning long enough so the user could click on the "Learn more"
                remarkHoverInfo.classList.remove('visible')
            }, 3000)
        }
    },
};