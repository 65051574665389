import angular from 'angular';
import routes from './alerts.router';
import AlertsComponent from './alerts.component';
import ActiveAlertsComponent from './active-alerts';
import ActiveAlertComponent from './active-alerts/alert';
import AlertLogComponent from './alert-log';
import RulesListComponent from './rules/rules.component';
import RuleDetailComponent from './rules/rules-detail';
import AlertsAndRulesServiceFactory from './alerts-and-rules.service';
import { RulesDeviceListComponent } from './rules/rules-detail/device-list.component';
import AlertChipPatternDirective from './rules/rules-detail/chip-pattern.directive';
import AlertDurationComponent from './rules/rules-detail/duration';
import dtCommon from '../../common';

const studioAlerts = 'studioAlerts';
export default studioAlerts;

angular.module(studioAlerts, [dtCommon])
    .component(AlertsComponent.selector, AlertsComponent.options)
    .component(RulesListComponent.selector, RulesListComponent.options)
    .component(RuleDetailComponent.selector, RuleDetailComponent.options)
    .component(ActiveAlertsComponent.selector, ActiveAlertsComponent.options)
    .component(ActiveAlertComponent.selector, ActiveAlertComponent.options)
    .component(AlertLogComponent.selector, AlertLogComponent.options)
    .component(RulesDeviceListComponent.selector, RulesDeviceListComponent.options)
    .component(AlertDurationComponent.selector, AlertDurationComponent.options)
    .directive('dtAlertChipPattern', AlertChipPatternDirective)
    .factory('AlertsAndRulesService', AlertsAndRulesServiceFactory)
    .config(routes);
