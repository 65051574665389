import controller from './alerts.controller';

const template = require('./alerts.html');

export default {
    selector: 'studioAlertsPage',
    options: {
        bindings: {
            activeState: '<',
        },
        controller,
        template
    }
};
