import { States } from '../../app.router';
import {
    dtDataConnectorIcon,
    dtApiIcon,
    dashboardIcon,
    listIcon,
    notificationsIcon,
    groupIcon,
    vpnKeyIcon,
    emulatorIcon,
    fileExportIcon
} from '../../common/icon/icons';

/**
 * @constructor
 * @ngInject
 */
export default function AppMenu() {
    /** @type AppMenuItem[] */
    const menu = [
        {
            name: 'Sensors & Cloud Connectors',
            svgIcon: listIcon,
            state: `${States.SENSORS}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
            activeOnStates: [
                States.SENSORS,
                States.SENSOR_DETAILS
            ],
            alertKey: "devices"
        },
        {
            name: 'Project Dashboard',
            svgIcon: dashboardIcon,
            state: `${States.DASHBOARD}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
            activeOnStates: [
                States.DASHBOARD,
                States.DASHBOARD_SPECIFIED,
            ],
        },
        {
            name: 'Notifications',
            svgIcon: notificationsIcon,
            state: `${States.RULES}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
            activeOnStates: [
                States.RULES,
                States.RULE_DETAIL
            ],
        },
        {
            name: 'Alerts',
            svgIcon: notificationsIcon,
            state: `${States.ALERTS}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
            readiness: 'alpha',
            featureFlag: 'notifications_alerts',
            activeOnStates: [
                States.ALERTS,
                States.ALERTS_LOG,
                States.ALERTS_RULES,
                States.ALERTS_RULES_DETAIL
            ],
        },
        {
            name: 'File Export',
            svgIcon: fileExportIcon,
            state: `${States.EXPORTS}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
            activeOnStates: [
                States.EXPORTS,
                States.EXPORT
            ]
        },
        {
            name: 'Project Settings',
            svgIcon: groupIcon,
            state: `${States.PROJECT_SETTINGS}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
            activeOnStates: [
                States.PROJECT_SETTINGS,
                States.PROJECT_SETTINGS_MEMBER
            ]
        },
        {
            name: 'API Integrations',
            svgIcon: dtApiIcon,
            state: '',
            activeOnStates: [],
            alertKey: "dataConnectors",
            children: [
                {
                    name: 'Data Connectors',
                    svgIcon: dtDataConnectorIcon,
                    state: `${States.DATA_CONNECTORS}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
                    activeOnStates: [
                        States.DATA_CONNECTORS,
                        States.DATA_CONNECTOR_DETAILS
                    ],
                    alertKey: "dataConnectors"
                },
                {
                    name: 'Service Accounts',
                    svgIcon: vpnKeyIcon,
                    state: `${States.SERVICE_ACCOUNTS}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
                    activeOnStates: [
                        States.SERVICE_ACCOUNTS,
                        States.SERVICE_ACCOUNT_DETAILS
                    ]
                },
                {
                    name: 'Emulator',
                    svgIcon: emulatorIcon,
                    state: `${States.EMULATOR}({projectId: $ctrl.ProjectManager.currentProjectId, query: null})`,
                    activeOnStates: [
                        States.EMULATOR,
                        States.EMULATOR_DETAILS
                    ]
                }
            ]
        },
    ];

    /**
     * @param {AppMenuItem} menuItem
     */
    return {
        menu: menu
    }
}
