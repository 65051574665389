
export default {
    chart: {
        animation: false,
        zoomType: 'x',
        spacingLeft: 0,
        spacingRight: 0,
        spacingBottom: 0,
        marginTop: 10,
        marginBottom: 22,
        marginRight: 0,
        marginLeft: 44,
        height: 176,
    },
    scrollbar: {
        enabled: false,
    },
    tooltip: {
        enabled: true,
        outside: false
    },
    yAxis: [
        {
            min: 0,
            max: 100,
            tickAmount: 6,
            labels: {
                format: '{value}%'
            }
        },
        {
            visible: false
        }
    ],
    xAxis: {
        minTickInterval: 30000, // 30 seconds
    },
    plotOptions: {
        line: {
            step: true,
            getExtremesFromAll: false,
            marker: {
                enabled: false
            }
        }
    }
};
