import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngMaterial from 'angular-material';

import * as Services from './services';
import * as Filters from './filters';
import AppRoutes from './app.router';
import { AppConfig, EventEmitter } from './app.config';
import AppBootstrap from './app.bootstrap';
import AppComponent from './app.settings';
import dtCommon from './common';
import dtApi from './services/api';
import dtComponents from './components';
import dtFeatureFlags from './modules/feature-flags';
import dtState from './modules/state';
import studioMenu from './modules/menu';
import studioRules from './modules/rules';
import studioAlerts from './modules/alerts';
import deviceEmulator from './modules/emulator';

import './app-styles';



const angularMoment = require('angular-moment');
const ngMessages = require('angular-messages');
const ngSanitize = require('angular-sanitize');

window.__DT_STUDIO_LOADED__ = true; // eslint-disable-line no-underscore-dangle


angular
    .module('app', [
        ngMessages,
        uiRouter,
        ngMaterial,
        ngSanitize,
        angularMoment,
        dtCommon,
        dtApi,
        dtFeatureFlags,
        dtComponents,
        dtState,
        studioMenu,
        studioRules,
        studioAlerts,
        deviceEmulator
    ])
    .component(AppComponent.selector, AppComponent.options)
    .config(AppRoutes)
    .config(AppConfig)
    .config(Services.Auth.InterceptorConfig)
    .value(EventEmitter.name, EventEmitter.definition)
    .service(Services.DialogService.name, Services.DialogService.definition)
    .service(Services.HealthCheckerService.name, Services.HealthCheckerService.definition)
    .service(Services.Auth.AuthService.name, Services.Auth.AuthService.definition)
    .service(Services.ToastService.name, Services.ToastService.definition)
    .service(Services.TranslationService.name, Services.TranslationService.definition)

    .service('ProjectManager', Services.ProjectManager)
    .service('RoleManager', Services.RoleManager)
    .service('StudioProfileService', Services.StudioProfileService)
    .service('ClipboardService', Services.ClipboardService)
    .service('CloudConnectorHelper', Services.CloudConnectorHelper)
    .service('BillingCheckHelper', Services.BillingCheckHelper)
    .service('StorageService', Services.StorageService)
    .service('UserPreferencesManager', Services.UserPreferencesManager)
    .service('AnnouncementManager', Services.AnnouncementManager)
    .service('SensorEventsLoader', Services.SensorEventsLoader)
    .factory('$exceptionHandler', Services.StackdriverExceptionHandler)
    .filter(Filters.Plural.name, Filters.Plural.definition)
    .run(AppBootstrap);
