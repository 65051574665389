
/**
 * @ngInject
 */
export default class TimelineController {
    constructor($mdDialog, alert) {
        this.$mdDialog = $mdDialog;
        this.alert = alert;
        
    }

    $onInit() {
      
    }

}
