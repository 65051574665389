import moment from 'moment';
import { getHistoryChartType, getHistoryEventType } from 'services/SensorHelper';
import { ConfigHelper, DataConverter } from 'services/charting';
import ConfigPresets from 'services/charting/presets';
import { AbstractChartController } from '../chart-abstract-base';


/* eslint class-methods-use-this: 0 */

/* @ngInject */
export default class ChartCO2OnlyController extends AbstractChartController {
    constructor(
        SensorEventsLoader,
        ToastService,
        $scope,
        EventEmitter,
    ) {
        super({
            SensorEventsLoader,
            ToastService,
            $rootScope: $scope.$root,
            EventEmitter,
        });
    }

    // Overrides AbstractChartController
    get dataSeries() {
        return {
            addPoint: (data) => {
                this.chart.series[0].addPoint(data, true)
            },
            setData: (data) => {
                this.chart.series[0].setData(data.map(item => [item[0], item[1]])); // CO2
                this.chart.series[2].setData(this.chartData.map(item => [item[0], item[2], item[3]])) // Min Max range
            }
        }
    }

    // Overrides AbstractChartController
    getEventTypes() {
        return [this.eventType]
    }

    // Overrides AbstractChartController
    getAggregationFields() {
        return [
            {"fieldName": "co2.ppm",                   "type": "AVERAGE"},
            {"fieldName": "co2.ppm",                   "type": "MIN"},
            {"fieldName": "co2.ppm",                   "type": "MAX"},
            // For offline detection
            {"fieldName": "co2.ppm",                   "type": "COUNT"},
        ];
    }

    // Overrides AbstractChartController
    getConfigPreset() {
        return ConfigPresets.CO2Only;
    }

    // Overrides AbstractChartController
    convertToSeries(data, spacerStart, spacerEnd, spacerMin, spacerMax) {
        return ConfigHelper.CO2OnlySeries(
            data,
            spacerStart,
            spacerEnd,
            spacerMin,
            spacerMax
        );
    }

    // Overrides AbstractChartController
    convertEvents(events) {

        const timestamps = [];
        events.forEach((event) => {
            if (event.eventType === 'co2') {
                timestamps.push(moment(event.data.co2?.updateTime).valueOf());
            }
        });

        this.plotBands = DataConverter.createOfflineBands(
            timestamps,
            this.chartMin,
            this.chartMax
        );
        this.chartData = DataConverter.co2(events);
        
        return {
            data: this.chartData,
            plotBands: this.plotBands
        };
    }

    // Overrides AbstractChartController
    convertAggregated(aggregatedData) {
        this.chartData = aggregatedData.results[0].values.map(aggWindow => {
            return [
                moment(aggWindow.timeWindow).valueOf(),                                         // Index 0
                aggWindow.values["co2.ppm_AVERAGE"],                                            // Index 1
                aggWindow.values["co2.ppm_MIN"],                                                // Index 2
                aggWindow.values["co2.ppm_MAX"],                                                // Index 3
            ]
        })

        this.plotBands = DataConverter.createAggregatedOfflineBands(
            aggregatedData.results[0].values,
            "co2.ppm_COUNT",
        );
        
        return {
            data: this.chartData,
            plotBands: this.plotBands,
        }
    }

    // Overrides AbstractChartController
    onStateEventReceived(eventData) {// eslint-disable-line no-unused-vars
        // TODO
    }

    redrawPlotBands() {
        // Ensure we have all plotBands from the actual chart
        const allPlotBandsIds = [] 
        this.xAxis.plotLinesAndBands.forEach(band => {
            if (band.id.startsWith('offline-since')) {
                allPlotBandsIds.push(band.id)
            }
        })
        // Clear them all before redraw
        allPlotBandsIds.forEach(bandId => { 
            this.xAxis.removePlotBand(bandId)
        })

        // Generate new bands with updated `sampleTimestamps`
        this.plotBands = DataConverter.createOfflineBands(
            this.sampleTimestamps,
            this.chartMin,
            this.chartMax,
            this.thing.productNumber
        );

        this.plotBands.forEach(band => { 
            this.xAxis.addPlotBand(band);
        });

    }

    adjustMinMaxValues(value) {
        if (value < this.spacerMin) {
            this.spacerMin = value;
        } else if (value > this.spacerMax) {
            this.spacerMax = value;
        }
    }

    $onInit() {
        this.chartType = getHistoryChartType(this.thing);
        this.eventType = getHistoryEventType(this.thing);
        super.$onInit();
    }
}
