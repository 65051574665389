import controller from './ccon-chart.controller';

const template = require('./ccon-chart.html');

export default {
    bindings: {
        thing: '<',
        eventReceived: '<',
        alertView: '<?',
        showBigFormat: '<?',
        parentDateRange: '<?',
    },
    template,
    controller
};
