import moment from 'moment';
import _merge from 'lodash/merge';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import _debounce from 'lodash/debounce';
import _findLast from 'lodash/findLast';

import ConfigPresets from 'services/charting/presets';
import { DataConverter, ConfigHelper } from 'services/charting';
import { PLOT_BAND_ETHERNET_PREFIX, PLOT_BAND_OFFLINE_PREFIX } from 'services/charting/data-converter';
import { noop } from 'services/utils';
import { APP_CONTENT_RESIZE_EVENT } from 'services/StudioEvents';
import { emptyArrayOnError } from 'services/api/helper';

const SECOND = 1000; // in milliseconds
const MINUTE = 60 * SECOND; // in milliseconds
const HOUR = 60 * MINUTE; // in milliseconds
const DAY = 24 * HOUR; // in milliseconds

const RANGE_LABEL_5_MIN = '5 min';
const RANGE_LABEL_HOUR = 'Hour';
const RANGE_LABEL_DAY = 'Day';
const RANGE_LABEL_WEEK = 'Week';
const RANGE_LABEL_ALL = 'Month';

const RANGE_DURATIONS = {
    [RANGE_LABEL_5_MIN]: 5 * MINUTE,
    [RANGE_LABEL_HOUR]: HOUR,
    [RANGE_LABEL_DAY]: DAY,
    [RANGE_LABEL_WEEK]: 7 * DAY
};

const MESSAGE_LOADING = 'Loading data from server...';

const CHART_X_AXIS_INDEX = 0;
const CHART_DATA_SERIES_INDEX = 1;
const CHART_SPACER_SERIES_INDEX = 0;

const ASYNC_EVENTS_CHUNK_COUNT = 4;

const PAN_TRACKING_DEBOUNCE_DELAY = 250; // in milliseconds

const CONNECTION_STATUS_EVENT_TYPE = 'connectionStatus';
const CELLULAR_STATUS_EVENT_TYPE = 'cellularStatus';

export const CCON_GRAPH_VISIBLE_STATE_KEY = 'ccon:cconGraphVisible';
export const CCON_GRAPH_RANGE_STATE_KEY = 'ccon:cconGraphRange';

/* @ngInject */
export default class ThingChartController {
    constructor(SensorService, $scope, $element, ToastService, $q, StateService) {
        this.SensorService = SensorService;
        this.$scope = $scope;
        this.elementNode = $element[0];
        this.ToastService = ToastService;
        this.$q = $q;
        this.StateService = StateService;

        this.handleEvents = this.handleEvents.bind(this);
        this.processEagerEvents = this.processEagerEvents.bind(this);
        this.processLazyEvents = this.processLazyEvents.bind(this);
        this.addHeartbeatEventPoint = this.addHeartbeatEventPoint.bind(this);
        this.updateSpacerSeries = this.updateSpacerSeries.bind(this);
        this.onChartSelection = this.onChartSelection.bind(this);
        this.onAfterSetExtremes = this.onAfterSetExtremes.bind(this);
        this.onChartLoaded = this.onChartLoaded.bind(this);
        this.loadMissingData = _debounce(this.loadMissingData.bind(this), PAN_TRACKING_DEBOUNCE_DELAY);
    }

    get xAxis() {
        return this.chart?.xAxis[CHART_X_AXIS_INDEX];
    }

    get dataSeries() {
        return this.chart?.series[CHART_DATA_SERIES_INDEX];
    }

    get spacerSeries() {
        return this.chart?.series[CHART_SPACER_SERIES_INDEX];
    }

    onChartLoaded({ chart }) {
        this.chart = chart;
        this.initResizeListener();
        if (this.storedRange) {
            this.setRange(this.storedRange);
        }
        this.addCellularStatusEventPoint();
    }

    onChartSelection() {
        Object.keys(this.ranges).forEach((key) => {
            this.ranges[key] = false;
        });
        this.$scope.$applyAsync();
    }

    onAfterSetExtremes({
        min, max, dataMax
    }) {
        this.stopSpacerTicker();
        if (ConfigHelper.isCloseToAxisMax(min, max, dataMax)) {
            const interval = ConfigHelper.getRefreshInterval(min, max);
            if (interval !== null) {
                this.initSpacerTicker(interval);
            }
        }
        this.loadMissingData();
    }

    prepareChartConfig(data, plotBands) {
        this.chartConfig = _merge(
            {
                chart: {
                    events: {
                        selection: this.onChartSelection
                    }
                }
            },
            ConfigPresets.Base,
            this.alertView ? ConfigPresets.AlertViewCCONConnectivity : ConfigPresets.CloudConnector,
            ConfigHelper.cellularSeries(data, this.spacerStart, this.spacerEnd, this.spacerMin, this.spacerMax),
            ConfigHelper.plotBands(plotBands),
            ConfigHelper.extremes(this.chartMin, this.chartMax),
            {
                xAxis: {
                    min: this.graphStart,
                    max: this.graphEnd,
                    events: {
                        afterSetExtremes: this.onAfterSetExtremes
                    }
                }
            }
        );
        if (this.alertView && this.showBigFormat) {
            this.chartConfig.chart.height = 276;    
        }
        this.dataLoaded = true;
    }

    addCellularStatusEventPoint(eventData = null) {
        let lastPointIndex = null;
        let lastPoint = null;

        if (this.chart === undefined) {
            return
        }

        if (this.dataSeries.data.length) {
            lastPointIndex = this.dataSeries.data.length - 1;
            lastPoint = this.dataSeries.data[lastPointIndex];
        }

        if (!eventData) {
            if (lastPoint) {
                const lastOfflinePlotBand = _findLast(
                    this.xAxis.plotLinesAndBands,
                    item => item?.options.id.startsWith(PLOT_BAND_OFFLINE_PREFIX)
                );

                if (lastPoint.options.x < lastOfflinePlotBand?.options.from) {
                    return;
                }

                const lastPointValue = lastPoint.y;

                if (lastPoint.options.isArtificial) {
                    this.dataSeries.removePoint(lastPointIndex, false);
                }

                const newPoint = {
                    x: this.chartMax,
                    y: lastPointValue,
                    isArtificial: true
                };
                this.dataSeries.addPoint(newPoint);
            }
            return;
        }

        if (lastPoint && lastPoint.options.isArtificial) {
            this.dataSeries.removePoint(lastPointIndex, false);
        }

        const timestamp = moment(eventData.updateTime).valueOf();
        const signalStrength = DataConverter.getRoundedFloatOrNull(eventData.signalStrength);
        const point = [timestamp, signalStrength];

        this.dataSeries.addPoint(point);
    }

    addConnectionStatusEventPoint(event = null) {
        let eventData = null;
        let ethernet = this.connectionStatuses.length
            ? this.connectionStatuses[this.connectionStatuses.length - 1].data[CONNECTION_STATUS_EVENT_TYPE].connection === 'ETHERNET' // eslint-disable-line max-len
            : false;
        let timestamp = this.chartMax;

        if (event) {
            eventData = event.data[CONNECTION_STATUS_EVENT_TYPE];
            ethernet = eventData.connection === 'ETHERNET';
            timestamp = moment(eventData.updateTime).valueOf();
        }

        if (ethernet) {
            let ethernetStart = null;
            let index = this.connectionStatuses.length - 1;
            let status;
            while (index >= 0) {
                status = this.connectionStatuses[index].data[CONNECTION_STATUS_EVENT_TYPE];
                if (status.connection === 'ETHERNET') {
                    if (ethernetStart === null) {
                        ethernetStart = moment(status.updateTime).valueOf();
                    } else {
                        ethernetStart = moment(status.updateTime).valueOf() < ethernetStart
                            ? moment(status.updateTime).valueOf()
                            : ethernetStart;
                    }
                    index--;
                } else {
                    break;
                }
            }

            if (ethernetStart) {
                const id = DataConverter.plotBandId(ethernetStart, PLOT_BAND_ETHERNET_PREFIX);
                this.xAxis.removePlotBand(id);
            } else {
                ethernetStart = timestamp;
            }

            this.xAxis.addPlotBand(
                DataConverter.plotBandForEthernet(ethernetStart, timestamp)
            );
        }

        if (event) {
            this.connectionStatuses.push(event);
        }
    }

    syncOfflinePlotBand(lastSeen) {
        const from = moment(lastSeen || this.chartMin).valueOf();
        const id = DataConverter.plotBandId(from);

        this.xAxis.removePlotBand(id);
        this.xAxis.addPlotBand(
            DataConverter.plotBand(from, this.chartMax)
        );
    }

    addHeartbeatEventPoint() {
        if (this.thing.offline) {
            this.syncOfflinePlotBand(_get(this.thing, 'reported.connectionStatus.updateTime', null));
        } else {
            this.addConnectionStatusEventPoint();
            this.addCellularStatusEventPoint();
        }
    }

    $onChanges(changes) {
        if (changes.eventReceived && !changes.eventReceived.isFirstChange()) {
            const event = changes.eventReceived.currentValue;
            if ([CONNECTION_STATUS_EVENT_TYPE, CELLULAR_STATUS_EVENT_TYPE].includes(event.eventType)) {
                if (event.eventType === CONNECTION_STATUS_EVENT_TYPE) {
                    this.addConnectionStatusEventPoint(event);
                } else {
                    this.addCellularStatusEventPoint(event.data[CELLULAR_STATUS_EVENT_TYPE]);
                }
                const { min, max, dataMax } = this.xAxis.getExtremes();
                if (ConfigHelper.isCloseToAxisMax(min, max, dataMax)) {
                    this.updateSpacerSeries();
                }
            }
        }
    }

    handleEvents(params, { data, nextPageToken }, dataContainer) {
        data.forEach((event) => {
            dataContainer.push(event);
        });

        if (nextPageToken) {
            return this.loadEvents({
                ...params,
                pageToken: nextPageToken
            }, dataContainer);
        }

        return dataContainer;
    }

    loadEvents(params, dataContainer = []) {
        return this.SensorService
            .events(this.thing.name, params)
            .then(data => this.handleEvents(params, data, dataContainer));
    }

    loadEventsAsync(params) {
        let { startTime, endTime } = params;

        startTime = moment(startTime).valueOf();
        endTime = moment(endTime).valueOf();

        const diff = endTime - startTime;

        if (diff > DAY) {
            const chunkStep = Math.floor(diff / ASYNC_EVENTS_CHUNK_COUNT);
            const promises = [];

            for (let i = 0; i < ASYNC_EVENTS_CHUNK_COUNT; i++) {
                const newStartTime = i === 0
                    ? startTime
                    : (startTime + (chunkStep * i) + 1);
                const newEndTime = (i === ASYNC_EVENTS_CHUNK_COUNT - 1)
                    ? endTime
                    : ((newStartTime + chunkStep) - (i === 0 ? 0 : 1));

                promises.push(this.loadEvents({
                    ...params,
                    startTime: moment(newStartTime).format(),
                    endTime: moment(newEndTime).format()
                }));
            }

            return this.$q.all(promises)
                .then(chunks => _sortBy([].concat(...chunks), [event => event.data[params.eventTypes[0]].updateTime]));
        }

        return this.loadEvents(params)
            .then(chunks => _sortBy(chunks, [event => event.data[params.eventTypes[0]].updateTime]));
    }

    setRange(label) {
        if (this.dataLoading) return;

        Object.keys(this.ranges).forEach((key) => {
            this.ranges[key] = false;
        });
        this.ranges[label] = true;

        this.StateService.setItem(CCON_GRAPH_RANGE_STATE_KEY, label);

        const chartMin = this.spacerStart;
        const chartMax = this.spacerEnd;

        const { max } = this.xAxis.getExtremes();
        if (label === RANGE_LABEL_ALL) {
            this.xAxis.setExtremes(chartMin, chartMax);
        } else {
            const newMin = max - RANGE_DURATIONS[label];
            if (newMin < chartMin) {
                this.xAxis.setExtremes(chartMin, chartMin + RANGE_DURATIONS[label]);
            } else {
                this.xAxis.setExtremes(newMin, max);
            }
        }
    }

    convertPlotBands(events) {
        const currentStatus = _get(this.thing, 'reported.connectionStatus', null);
        return [
            ...DataConverter.cconOffline(events, this.chartMin, this.chartMax, currentStatus),
            ...DataConverter.cconEthernet(events, this.chartMin, this.chartMax, currentStatus)
        ];
    }

    saveConnectionStatuses(events) {
        const connectionStatus = _get(this.thing, 'reported.connectionStatus', null);
        const defaultStatuses = connectionStatus ? [{ data: { connectionStatus } }] : [];
        this.connectionStatuses = events[CONNECTION_STATUS_EVENT_TYPE].length
            ? events[CONNECTION_STATUS_EVENT_TYPE]
            : defaultStatuses;
    }

    processEagerEvents(events) {
        const plotBands = this.convertPlotBands(this.connectionStatuses) // `connectionStatuses` will contain the latest reported connectionStatus if there are no events
        const data = DataConverter.cellular(events[CELLULAR_STATUS_EVENT_TYPE]);
        this.prepareChartConfig(data, plotBands);
    }

    processLazyEvents(events) {
        const data = DataConverter.cellular(events);

        this.dataSeries.setData(data);
        this.addCellularStatusEventPoint();
    }

    loadDataForDates(start, end) {
        this.chartMin = start.valueOf();
        this.chartMax = end.valueOf();

        this.graphStart = moment().subtract(1, 'day').valueOf();
        this.graphEnd = moment().valueOf();

        this.dataLoading = true;

        return this.loadEventsAsync({
            startTime: start.format(),
            endTime: end.format(),
            eventTypes: [CELLULAR_STATUS_EVENT_TYPE]
        })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('device_events_wasnt_loaded', {
                    serverResponse
                });
                return [];
            })
            .finally(() => {
                this.dataLoading = false;
            });
    }

    loadDataForDay() {
        const start = moment().subtract(1, 'day').startOf('day');
        const end = moment();

        this.dataLoadedSince = start;

        const connectionStatusPromise = this.loadEventsAsync({
            startTime: moment().subtract(40, 'day').format(),
            endTime: end.format(),
            eventTypes: [CONNECTION_STATUS_EVENT_TYPE]
        }).catch(emptyArrayOnError);

        this.$q.all({
            [CONNECTION_STATUS_EVENT_TYPE]: connectionStatusPromise,
            [CELLULAR_STATUS_EVENT_TYPE]: this.loadDataForDates(start, end)
        })
            .then((events) => {
                this.saveConnectionStatuses(events);
                this.processEagerEvents(events);
            });
    }

    loadDataSince(start) {
        const end = moment();

        this.chart.showLoading(MESSAGE_LOADING);
        this.loadingSince = true;
        this.stopSpacerTicker();

        this.loadDataForDates(start, end).then((events) => {
            this.processLazyEvents(events);
            this.dataLoadedSince = start;
            this.loadingSince = false;
            this.chart.hideLoading();
            if (this.needsReloadSince) {
                this.needsReloadSince = false;
                this.loadMissingData();
            }
        });
    }

    loadMissingData() {
        if (this.loadingSince) {
            this.needsReloadSince = true;
            return;
        }

        const { min } = this.xAxis.getExtremes();

        let start = moment(min).startOf('day');
        if (start.diff(this.spacerStart) < 0) {
            start = moment(this.spacerStart);
        }

        if (start.diff(this.dataLoadedSince) < 0) {
            this.loadDataSince(start);
        }
    }

    $onInit() {
        this.dataLoaded = false;
        this.connectionStatuses = [];
        this.graphVisible = false;
        this.firstTouch = true;

        this.ranges = {
            [RANGE_LABEL_DAY]: true,
            [RANGE_LABEL_WEEK]: false,
            [RANGE_LABEL_ALL]: false
        };
        this.storedRange = this.StateService.getItem(CCON_GRAPH_RANGE_STATE_KEY);

        this.weekDataLoaded = false;
        this.monthDataLoaded = false;

        this.spacerStart = moment().subtract(1, 'month').valueOf();
        this.spacerEnd = moment().valueOf();

        this.spacerMin = 0;
        this.spacerMax = 0;

        this.dataLoadedSince = null;

        this.loadingSince = false;
        this.needsReloadSince = false;

        this.removeResizeListener = noop;

        this.loadDataForDay();

        this.$scope.$watch('$ctrl.parentDateRange', (newRange) => {
            if (newRange) {
                this.setRange(newRange);
            }
        });
    }

    updateSpacerSeries() {
        this.chartMax = moment().valueOf();
        this.spacerEnd = this.chartMax;
        this.spacerSeries.setData(
            ConfigHelper.spacerSeriesData(this.spacerStart, this.spacerEnd, this.spacerMin, this.spacerMax)
        );
        this.addHeartbeatEventPoint();
    }

    initSpacerTicker(delay) {
        this.spacerTicker = setTimeout(this.updateSpacerSeries, delay);
    }

    stopSpacerTicker() {
        clearTimeout(this.spacerTicker);
    }

    initResizeListener() {
        this.removeResizeListener = this.$scope.$root.$on(APP_CONTENT_RESIZE_EVENT, () => {
            this.elementNode.style.width = '250px'; // any value that makes container narrow enough
            this.chart.reflow();
            this.elementNode.style.width = ''; // spread over available space again

            setTimeout(() => {
                this.chart.reflow();
            }, 0);
        });
    }

    $onDestroy() {
        this.removeResizeListener();
        this.stopSpacerTicker();
    }
}
