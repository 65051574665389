import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import { scrollContainerToActiveChild, noop } from 'services/utils';
import { METRIC_KEYS, UNAVAILABLE_METRICS_RESPONSE } from 'services/api/DataConnectorService';
import { CREATE_DATACONNECTOR, UPDATE_DATACONNECTOR, DELETE_DATACONNECTOR } from 'services/Permissions';

const EVENT_KEYS = [
    'touch',
    'temperature',
    'contact',
    'objectPresent',
    'batteryStatus',
    'networkStatus',
    'labelsChanged',
    'connectionStatus',
    'ethernetStatus',
    'cellularStatus',
    'objectPresentCount',
    'touchCount',
    'humidity',
    'waterPresent',
    'co2',
    'pressure',
    'motion',
    'deskOccupancy'
];

const EVENT_META_DATA = {
    touch: {
        description: 'When device is touched',
        label: 'All devices'
    },
    temperature: {
        description: 'Every periodic heartbeat & when touched',
        label: 'Temperature sensors'
    },
    contact: {
        description: 'When a door or window is opened or closed',
        label: 'Door & Window sensors'
    },
    objectPresent: {
        description: 'When an object in close proximity appears or disappears',
        label: 'Proximity sensors'
    },
    batteryStatus: {
        description: 'Approximately once per day',
        label: 'All sensors'
    },
    networkStatus: {
        description: 'Every periodic heartbeat or with other sensor events',
        label: 'All sensors'
    },
    labelsChanged: {
        description: 'When a device label is created, modified or removed',
        label: 'All devices'
    },
    connectionStatus: {
        description: 'When a Sensor or Cloud Connector changes its connection status, for example going offline.',
        label: 'All devices'
    },
    ethernetStatus: {
        description: 'When the ethernet connection changes',
        label: 'Cloud Connectors'
    },
    cellularStatus: {
        description: 'When the cellular connection changes',
        label: 'Cloud Connectors'
    },
    objectPresentCount: {
        description: 'Every periodic heartbeat',
        label: 'Proximity counting sensors'
    },
    touchCount: {
        description: 'Every periodic heartbeat',
        label: 'Counting touch sensors'
    },
    humidity: {
        description: 'Every periodic heartbeat (includes temperature)',
        label: 'Humidity sensors & CO2 sensors'
    },
    waterPresent: {
        description: 'When the sensor detects the appearance or disappearance of water',
        label: 'Water detector sensors'
    },
    co2: {
        description: 'Every periodic heartbeat',
        label: 'CO2 sensors'
    },
    pressure: {
        description: 'Every periodic heartbeat',
        label: 'CO2 sensors'
    },
    motion: {
        description: 'When motion is detected or no longer detected',
        label: 'Motion sensors'
    },
    deskOccupancy: {
        description: 'Every state change',
        label: 'Desk occupancy sensors'
    }
};

const EVENT_KEYS_ROW_THRESHOLD = Math.ceil(EVENT_KEYS.length / 2);

/* @ngInject */
export default class DataConnectorController {
    constructor(
        EventEmitter,
        $transitions,
        DialogService,
        $state,
        $timeout,
        DataConnectorService,
        RoleManager,
        ToastService,
        AnalyticsService,
        $scope
    ) {
        this.EventEmitter = EventEmitter;
        this.$transitions = $transitions;
        this.DialogService = DialogService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.DataConnectorService = DataConnectorService;
        this.toastService = ToastService;
        this.AnalyticsService = AnalyticsService;
        this.RoleManager = RoleManager;
        this.$scope = $scope;

        this.onExitGuard = this.onExitGuard.bind(this);
    }

    onExitGuard() {
        if (
            !_isEqual(_omit(this.originalDataConnector, METRIC_KEYS), _omit(this.dataConnector, METRIC_KEYS))
            || this.noEventsSelected
        ) {
            return this.DialogService.confirm({
                title: 'Data Connector was edited',
                textContent: 'Do you really want to leave? Changes will not be saved.',
                ariaLabel: 'drop-changes',
                ok: 'Leave',
                cancel: 'Stay'
            })
                .then(() => true)
                .catch(() => false);
        }
        return true;
    }

    $onInit() {
        this.originalDataConnector = _cloneDeep(this.dataConnector);

        this.unregisterExitGuard = this.$transitions.onExit({
            exiting: this.$state.current.name
        }, this.onExitGuard);

        this.eventKeyRows = [
            EVENT_KEYS.slice(0, EVENT_KEYS_ROW_THRESHOLD),
            EVENT_KEYS.slice(EVENT_KEYS_ROW_THRESHOLD)
        ];
        this.eventMetaData = EVENT_META_DATA;
        this.buildEventMap();

        this.endpointUrlRegex = /^https:\/\//;
        this.serviceBusRegex = /^https:\/\/[a-zA-Z0-9-]+\.servicebus\.windows\.net\/[a-zA-Z0-9-]+$/;
        this.clientTenantIdRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        this.googlePubSubTopicRegex = /^projects\/\S+\/topics\/\S+$/;
        this.googlePubSubAudienceRegex = /^\/\/iam\.googleapis\.com\/projects\/\S+\/locations\/\S+\/workloadIdentityPools\/\S+\/providers\/\S+$/;

        this.$timeout(scrollContainerToActiveChild);

        if (!this.isNew) {
            this.updateMetrics(true);
        }

        this.allEvents = !this.dataConnector.events.length;
        if (this.allEvents) {
            this.setAllEventsTo(true);
        }

        this.endpointUrlFieldName = 'endpointUrl';
        this.endpointUrlFieldEdited = false;

        this.showNoEventsSelectedError = false;

        this.isSynchronizing = false;

        this.showEventOptions = !this.allEvents
        this.showAzureServiceBusOptions = false
    }

    toggleShowEventOptions() {
        this.showEventOptions = !this.showEventOptions
        this.$scope.$applyAsync()
    }

    markEdited(fieldName = null) {
        if (!this.endpointUrlFieldEdited && fieldName === this.endpointUrlFieldName) {
            this.endpointUrlFieldEdited = true;
            this.$scope.$applyAsync();
        }
    }

    $onDestroy() {
        this.unregisterExitGuard();
    }

    buildEventMap() {
        this.eventMap = EVENT_KEYS.reduce((acc, eventKey) => {
            acc[eventKey] = this.dataConnector.events.indexOf(eventKey) >= 0;
            return acc;
        }, {});
    }

    get canCreateNew() {
        return this.RoleManager.can(CREATE_DATACONNECTOR);
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DATACONNECTOR);
    }

    get canDelete() {
        return this.RoleManager.can(DELETE_DATACONNECTOR);
    }

    saveDataConnector() {
        if (this.noEventsSelected) {
            this.showNoEventsSelectedError = true;
            return;
        }
        if (this.dataConnector.status === 'SYSTEM_DISABLED') {
            this.dataConnector.status = 'USER_DISABLED';
        }
        
        this.AnalyticsService.trackEvent("dataconnector.updated")
        
        this.onUpdate(
            this.EventEmitter({
                dataConnector: this.dataConnector,
                patch: _omit(this.dataConnector, ['name', 'type', 'errorCount', 'latency99p', 'successCount'])
            })
        ).then(() => {
            this.originalDataConnector = _cloneDeep(this.dataConnector);
        });
    }

    updateLabels({ entities }) {
        this.dataConnector.labels = [...entities]; // break reference
    }

    setAllEventsTo(checked) {
        Object.keys(this.eventMap).forEach((key) => {
            this.eventMap[key] = checked;
        });
    }

    get noEventsSelected() {
        return !this.allEvents && !this.dataConnector.events.length;
    }

    onEventsChanged(allEventsToggled = false) {
        if (allEventsToggled) {
            this.setAllEventsTo(this.allEvents);
        }
        if (this.allEvents) {
            this.dataConnector.events = [];
        } else {
            this.showEventOptions = true
            this.dataConnector.events = EVENT_KEYS.filter(eventKey => this.eventMap[eventKey]);
        }
        if (this.showNoEventsSelectedError) {
            this.showNoEventsSelectedError = false;
        }
    }

    createDataConnector() {

        if (this.dataConnector.type === 'AZURE_SERVICE_BUS') {
            delete this.dataConnector.httpConfig;
        }

        if (this.dataConnector.type === 'HTTP_PUSH') {
            delete this.dataConnector.azureServiceBusConfig;
        }

        if (this.noEventsSelected) {
            this.showNoEventsSelectedError = true;
            return;
        }
        
        this.AnalyticsService.trackEvent("dataconnector.created")

        this.onCreate(
            this.EventEmitter({
                dataConnector: this.dataConnector
            })
        ).then(() => {
            this.originalDataConnector = _cloneDeep(this.dataConnector);
        });
    }

    deleteDataConnector() {
        this.onDelete(this.EventEmitter({
            dataConnector: this.originalDataConnector,
        }))
            .then(() => {
                this.dataConnector = _cloneDeep(this.originalDataConnector);
            })
            .catch(noop);
    }

    updateMetrics(quite = false) {
        this.AnalyticsService.trackEvent("dataconnector.metrics.refresh")

        const targetNode = document.querySelector('#metrics-refresh-icon');
        if (targetNode) {
            targetNode.classList.add('spinning');
        }
        this.DataConnectorService
            .dataConnectorMetrics(this.dataConnector.name)
            .then((response) => {
                Object.assign(this.dataConnector, response.metrics);
                this.onMetricsUpdate(
                    this.EventEmitter({
                        dataConnector: this.dataConnector,
                        metrics: response.metrics
                    })
                );
                if (!quite) {
                    this.toastService.showSimpleTranslated('dataconnector_metrics_was_refreshed');
                }
            })
            .catch((serverResponse) => {
                Object.assign(this.dataConnector, UNAVAILABLE_METRICS_RESPONSE.metrics);
                this.onMetricsUpdate(
                    this.EventEmitter({
                        dataConnector: this.dataConnector,
                        metrics: UNAVAILABLE_METRICS_RESPONSE.metrics
                    })
                );
                this.toastService.showSimpleTranslated('dataconnector_metrics_wasnt_refreshed', {
                    serverResponse
                });
            })
            .finally(() => {
                if (targetNode) {
                    targetNode.classList.remove('spinning');
                }
            });
    }

    showSynchronizeDialog() {
        if (this.isSynchronizing) {
            return;
        }
        this.isSynchronizing = true;
        this.DialogService.confirm({
            title: 'Synchronize Data Connector',
            textContent: 'Are you sure you want to start synchronizing the Data Connector?',
            ariaLabel: 'synchronize-data-connector',
            ok: 'Yes',
            cancel: 'Cancel'
        })
            .then(() => this.synchronizeDataConnector())
            .catch(() => false)
            .finally(() => {
                this.isSynchronizing = false;
            });
    }

    synchronizeDataConnector() {
        return this.DataConnectorService
            .synchronizeDataConnector(this.dataConnector.name)
            .then(() => {
                this.AnalyticsService.trackEvent("dataconnector.synchronization.started")
                this.toastService.showSimpleTranslated('dataconnector_sync_started');
            })
            .catch((serverResponse) => {
                let toastResponse = '';
                switch (serverResponse.status) {
                    case 409:
                        this.AnalyticsService.trackEvent("dataconnector.synchronization.already_in_progress")
                        toastResponse = 'dataconnector_sync_already_in_progress';
                        break;
                    default:
                        this.AnalyticsService.trackEvent("dataconnector.synchronization.failed")
                        toastResponse = 'dataconnector_sync_failed';
                }
                this.toastService.showSimpleTranslated(toastResponse, { serverResponse });
            });
    }

    typeDisplayName(type) {
        if (type === 'HTTP_PUSH') {
            return 'Webhook';
        }
        if (type === 'AZURE_SERVICE_BUS') {
            return 'Azure Service Bus';
        }
        if (type === 'AZURE_EVENT_HUB') {
            return 'Azure Event Hub';
        }
        if (type === 'GOOGLE_CLOUD_PUBSUB') {
            return 'Goole Cloud Pub/Sub'
        }
        return type
    }
}
